import React from 'react'
 
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import CommonBanner from '../Components/CommonBanner'
import FamilyInsuranceComponent from '../Components/FamilyInsuranceComponent'
import ScrollToTop from '../Components/ScrollToTop'

const FamilyInsurance = () => {
  return (
    <div>    
        <Header/>
        <CommonBanner Name={"Family Insurance"} />
        <FamilyInsuranceComponent/>
        <Footer/>
        <ScrollToTop/>
        <div className="scroll-to-top">
  <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
  </svg>
</div>
    </div>
  )
}

export default FamilyInsurance