import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
            {/*Site Footer Start*/}
    <footer className="site-footer site-footer-three">
      <div className="site-footer__shape-1 float-bob-x">
        <img src="../assets/images/shapes/site-footer-three-shape-1.png"  />
      </div>
      <div className="site-footer__top">
        <div className="container-fluid">
          <div className="site-footer__top-inner">
            <div className="row mybox-2">
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                <div className="footer-widget__column footer-widget__about">
                  <div className="footer-widget__logo">
                    <Link to="/"><img style={{height:"130px",marginLeft:"-10px"}} src="../assets/images/logo-insurify.png"  /></Link>
                  </div>
                  <p className="footer-widget__about-text text-white">Chaman Medicos, Mata Darwaja Chowk,Rohtak-124001</p>
                  <div className="footer-widget__emergency-call">
                    <a href="tel:+919255433099">+91 9255433099</a>
                  </div>
                  <div className="footer-widget__emergency-call">
                    <a href="mailto:A2ZSolutionsRTK@gmail.com">A2ZSolutionsRTK@gmail.com</a>
                  </div>
                  <div className="footer-widget__emergency-call">
                    <a href="https://api.whatsapp.com/send/?phone=%2B919255433099&text=Hello%2C+I+want+insurance+from+your+A2Z+Solutions&type=phone_number&app_absent=0"><img style={{height:"20px"}} src="./assets/images/whatsapp.png" alt="" />  Whatsapp</a>
                  </div>
                  {/* <div className="footer-widget__social">
                    <a href="#"><span className="icon-facebook" /></a>
                    <a href="#"><span className="icon-instagram-1" /></a>
                    <a href="#"><span className="icon-tik-tok" /></a>
                    <a href="#"><span className="icon-youtube" /></a>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                <div className="footer-widget__column footer-widget__navigation">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Quick Links</h3>
                  </div>
                  <ul className="footer-widget__navigation-list list-unstyled">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/About">About Us</Link>
                    </li>
                    <li>
                      <Link to="/Blog">Our Blogs</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/FAQ">FAQ</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                <div className="footer-widget__column footer-widget__quick-link">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Insurance</h3>
                  </div>
                  <ul className="footer-widget__navigation-list list-unstyled">
                    <li>
                      <Link to="/LifeInsurance">Life Insurance</Link>
                    </li>
                    <li>
                      <Link to="/HealthInsurance">Health Insurance</Link>
                    </li>
                    <li>
                      <Link to="/CarInsurance">Car Insurance</Link>
                    </li>
                    <li>
                      <Link to="/HomeInsurance">Home Insurance</Link>
                    </li>
                    <li>
                      <Link to="/FamilyInsurance">Family Insurance</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                <div className="footer-widget__column footer-widget__gallery">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Our Aim</h3>
                  </div>
                  <p style={{textAlign:"justify",color:"white"}}>Our Aim is to help clients select <br />suitable insurance policies to protect <br /> their financial well-being and provide <br /> ongoing support and guidance.</p>
                  {/* <ul className="footer-widget__navigation-list list-unstyled">
                    <li className='detailshover'>
                      <a href="tel:+919255433099">+91 9255433099</a>
                    </li>
                    <li className='detailshover'>
                      <a href="mailto:A2ZSolutionsRTK@gmail.com">A2ZSolutionsRTK@gmail.com</a>
                    </li>
                    <li className='detailshover'>
                      <a href="https://api.whatsapp.com/send/?phone=%2B919255433099&text=Hello%2C+I+want+insurance+from+your+A2Z+Solutions&type=phone_number&app_absent=0">Whatsapp</a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-footer__bottom">
        <div className="container">
          <div className="site-footer__bottom-inner">
            <p className="site-footer__bottom-text">Copyright © 2024 A2Z Solutions Designed By <a href="mailto:designinghub72@gmail.com">Designing Hub.</a> All
              Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
    {/*Site Footer End*/}
    </div>
  )
}

export default Footer