import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css'; // Import Swiper's core styles
import 'swiper/css/navigation'; // Import Swiper's navigation styles
import 'swiper/css/pagination'; // Import Swiper's pagination styles
import { Autoplay, Parallax, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const HomeServices = () => {
  return (
    <div>
      {/*Services One Start */}
      <section className="services-one">
        <div className="services-one__shape-1 float-bob-x">
          <img src="assets/images/shapes/services-one-shape-1.png"  />
        </div>
        <div className="services-one__shape-2 rotate-me">
          <img src="assets/images/shapes/services-one-shape-2.png"  />
        </div>
        <div className="container">
          <div className="section-title text-left sec-title-animation animation-style1">
            <div className="section-title__tagline-box">
              <p className="section-title__tagline">A2Z Solution POPULAR SERVICE</p>
            </div>
            <h2 className="section-title__title title-animation">Amazing Products
              <br /> that Protect and reward</h2>
          </div>
          <div className="services-one__bottom">
          <Swiper breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }} spaceBetween={10}
                slidesPerView={4}
                modules={[Autoplay, Parallax, Pagination, Navigation]}
                // autoplay={{ delay: 3000 }}
                // speed={2000}
                pagination={{
                  clickable: true}}
                parallax={true} navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }} className="swiper-container-h" >
                  <div className="swiper-button-prev"></div>
            <div className="services-one__carousel owl-carousel owl-theme thm-owl__carousel" >
             
                {/*Services One Single Start*/}
                <SwiperSlide>
                  <div className="wiperSlide>item">
                    <div className="services-one__single">
                      <div className="services-one__title-box">
                        <h3 className="services-one__title"><Link to="/FamilyInsurance">Family Insurance</Link>
                        </h3>
                      </div>
                      <div className="services-one__img-box">
                        <div className="services-one__img">
                          <img src="assets/images/services/services-1-1.jpg"  />
                        </div>
                        <div className="services-one__icon">
                          <span className="icon-family-insurance" />
                        </div>
                      </div>
                      <div className="services-one__read-more">
                        <Link to="/FamilyInsurance">READ MORE<span className="icon-next" /></Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                {/*Services One Single End*/}
                {/*Services One Single Start*/}
                <SwiperSlide>
                  <div className="item">
                    <div className="services-one__single">
                      <div className="services-one__title-box">
                        <h3 className="services-one__title"><Link to="/HealthInsurance">Medical
                          Insurance</Link>
                        </h3>
                      </div>
                      <div className="services-one__img-box">
                        <div className="services-one__img">
                          <img src="assets/images/services/services-1-2.jpg"  />
                        </div>
                        <div className="services-one__icon">
                          <span className="icon-healthcare" />
                        </div>
                      </div>
                      <div className="services-one__read-more">
                        <Link to="/HealthInsurance">READ MORE<span className="icon-next" /></Link>
                      </div>
                    </div>
                  </div></SwiperSlide>
                {/*Services One Single End*/}
                {/*Services One Single Start*/}
                <SwiperSlide>
                  <div className="item">
                    <div className="services-one__single">
                      <div className="services-one__title-box">
                        <h3 className="services-one__title"><Link to="/BussinessInsurance">Business
                          Insurance</Link>
                        </h3>
                      </div>
                      <div className="services-one__img-box">
                        <div className="services-one__img">
                          <img src="assets/images/services/services-1-3.jpg"  />
                        </div>
                        <div className="services-one__icon">
                          <span className="icon-risk-management" />
                        </div>
                      </div>
                      <div className="services-one__read-more">
                        <Link to="/BussinessInsurance">READ MORE<span className="icon-next" /></Link>
                      </div>
                    </div>
                  </div></SwiperSlide>
                {/*Services One Single End*/}
                {/*Services One Single Start*/}
                <SwiperSlide>
                  <div className="item">
                    <div className="services-one__single">
                      <div className="services-one__title-box">
                        <h3 className="services-one__title"><Link to="/LifeInsurance">Life Insurance</Link>
                        </h3>
                      </div>
                      <div className="services-one__img-box">
                        <div className="services-one__img">
                          <img src="assets/images/services/services-1-1.jpg"  />
                        </div>
                        <div className="services-one__icon">
                          <span className="icon-family-insurance" />
                        </div>
                      </div>
                      <div className="services-one__read-more">
                        <Link to="/LifeInsurance">READ MORE<span className="icon-next" /></Link>
                      </div>
                    </div>
                  </div></SwiperSlide>
                {/*Services One Single End*/}
                {/*Services One Single Start*/}
                <SwiperSlide>
                  <div className="item">
                    <div className="services-one__single">
                      <div className="services-one__title-box">
                        <h3 className="services-one__title"><Link to="/HomeInsurance">Home
                          Insurance</Link>
                        </h3>
                      </div>
                      <div className="services-one__img-box">
                        <div className="services-one__img">
                          <img src="assets/images/services/home-insurance.jpg"  />
                        </div>
                        <div className="services-one__icon">
                        <img src="./assets/images/home-insurance.png" alt="" />
                        </div>
                      </div>
                      <div className="services-one__read-more">
                        <Link to="/HomeInsurance">READ MORE<span className="icon-next" /></Link>
                      </div>
                    </div>
                  </div></SwiperSlide>
                {/*Services One Single End*/}
                {/*Services One Single Start*/}
                <SwiperSlide>
                  <div className="item">
                    <div className="services-one__single">
                      <div className="services-one__title-box">
                        <h3 className="services-one__title"><Link to="/CarInsurance">Car
                          Insurance</Link>
                        </h3>
                      </div>
                      <div className="services-one__img-box">
                        <div className="services-one__img">
                          <img src="assets/images/services/car-insurance.jpg"  />
                        </div>
                        <div className="services-one__icon">
                          <img src="./assets/images/car-insurance.png" alt="" />
                        </div>
                      </div>
                      <div className="services-one__read-more">
                        <Link to="/CarInsurance">READ MORE<span className="icon-next" /></Link>
                      </div>
                    </div>
                  </div></SwiperSlide>
             
              {/*Services One Single End*/}
            </div>
            <div className="swiper-button-next">
            </div>
            </Swiper>
          </div>
        </div>
      </section>
      {/*Services One End */}
    </div>
  )
}

export default HomeServices