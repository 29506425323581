import React from 'react'
import { Link } from 'react-router-dom'

const TestimonialsComponent = () => {
  return (
    <div>
{/*Testimonial Page Start*/}
<section className="testimonial-page">
  <div className="container">
    <div className="row">
      {/*Testimonial One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="testimonial-one__single">
          <div className="testimonial-one__quote">
            <img src="assets/images/icon/quote-icon-1.png"  />
          </div>
          <div className="testimonial-one__text-box">
            <p className="testimonial-one__text">I recieved help from Star during my surgery and revovery. And my faith in the company has increased a lot. Thanks a lot to them and their team.</p>
          </div>
          <div className="testimonial-one__client-info">
            <div className="testimonial-one__client-img">
              <img src="assets/images/textimonial.png"  />
            </div>
            <h3 className="testimonial-one__client-name"><Link to="/Testimonials">Damayanti Upandhye</Link></h3>
            <p className="testimonial-one__client-sub-title">Entrepreneur</p>
          </div>
        </div>
      </div>
      {/*Testimonial One Single End*/}
      {/*Testimonial One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="testimonial-one__single">
          <div className="testimonial-one__quote">
            <img src="assets/images/icon/quote-icon-1.png"  />
          </div>
          <div className="testimonial-one__text-box">
            <p className="testimonial-one__text">This insurance company truly understands the value of customer service. They always put me first and have made me a customer for life.</p>
          </div>
          <div className="testimonial-one__client-info">
            <div className="testimonial-one__client-img">
              <img src="assets/images/testimonial-LDXAEEM-150x150.PNG"  />
            </div>
            <h3 className="testimonial-one__client-name"><Link to="/Testimonial">Sanjay Tandon</Link></h3>
            <p className="testimonial-one__client-sub-title">Marketing -Zintra</p>
          </div>
        </div>
      </div>
      {/*Testimonial One Single End*/}
      {/*Testimonial One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="testimonial-one__single">
          <div className="testimonial-one__quote">
            <img src="assets/images/icon/quote-icon-1.png"  />
          </div>
          <div className="testimonial-one__text-box">
            <p className="testimonial-one__text">Thanks to this insurance company, I&#039;m able to sleep soundly at night knowing that I&#039;m protected. Their policies are comprehensive.</p>
          </div>
          <div className="testimonial-one__client-info">
            <div className="testimonial-one__client-img">
              <img src="assets/images/testtimonial1.PNG"  />
            </div>
            <h3 className="testimonial-one__client-name"><Link to="/Testimonial">Abhi Patvardhan</Link></h3>
            <p className="testimonial-one__client-sub-title">Businessman</p>
          </div>
        </div>
      </div>
      {/*Testimonial One Single End*/}
      {/*Testimonial One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="testimonial-one__single">
          <div className="testimonial-one__quote">
            <img src="assets/images/icon/quote-icon-1.png"  />
          </div>
          <div className="testimonial-one__text-box">
            <p className="testimonial-one__text">I&#039;ve recommended this insurance company to all my friends & family because they provide such great coverage at an affordable price.</p>
          </div>
          <div className="testimonial-one__client-info">
            <div className="testimonial-one__client-img">
              <img src="assets/images/testimonial-83S5W35-150x150.PNG"   />
            </div>
            <h3 className="testimonial-one__client-name"><Link to="/Testimonials">Sarabjit Kaur</Link></h3>
            <p className="testimonial-one__client-sub-title">Housewife</p>
          </div>
        </div>
      </div>
      {/*Testimonial One Single End*/}
      {/*Testimonial One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="testimonial-one__single">
          <div className="testimonial-one__quote">
            <img src="assets/images/icon/quote-icon-1.png"  />
          </div>
          <div className="testimonial-one__text-box">
            <p className="testimonial-one__text">I recently had to file a claim with my insurance company and was blown away by how efficient and easy the process was.</p>
          </div>
          <div className="testimonial-one__client-info">
            <div className="testimonial-one__client-img">
              <img src="assets/images/portrait-of-successful-senior-businessman-gray-PRHRJ6H-150x150.PNG"  />
            </div>
            <h3 className="testimonial-one__client-name"><Link to="/Testimonial">Govind Sharma</Link>
            </h3>
            <p className="testimonial-one__client-sub-title">Google</p>
          </div>
        </div>
      </div>
      {/*Testimonial One Single End*/}
      {/*Testimonial One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="testimonial-one__single">
          <div className="testimonial-one__quote">
            <img src="assets/images/icon/quote-icon-1.png"  />
          </div>
          <div className="testimonial-one__text-box">
            <p className="testimonial-one__text">It provides you with financial protection in the event of unexpected death, illness & injury. It also protect us & our families with compassion of security of shield.</p>
          </div>
          <div className="testimonial-one__client-info">
            <div className="testimonial-one__client-img">
              <img src="assets/images/textimonial2.png"  />
            </div>
            <h3 className="testimonial-one__client-name"><Link to="/Testimonials">Gaurav Bhatia</Link>
            </h3>
            <p className="testimonial-one__client-sub-title">Manager</p>
          </div>
        </div>
      </div>
      {/*Testimonial One Single End*/}
    </div>
  </div>
</section>
{/*Testimonial Page End*/}

    </div>
  )
}

export default TestimonialsComponent