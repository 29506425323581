import React from 'react'
import Header from '../Components/Header'
import CommonBanner from '../Components/CommonBanner'
import Footer from '../Components/Footer'
import BussinessInsuranceComponent from '../Components/BussinessInsuranceComponent'
import ScrollToTop from '../Components/ScrollToTop'

const BussinessInsurance = () => {
  return (
    <div>
        <Header/>
        <CommonBanner Name={"Business Insurance"} />
        <BussinessInsuranceComponent/>
        <Footer/>
        <ScrollToTop/>
    </div>
  )
}
export default BussinessInsurance