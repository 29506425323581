import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'
import { useNavigate } from 'react-router-dom'

const HomeBlog = () => {
  const [Obj, SetObj] = useState("")
  const navigate=useNavigate()
  useEffect(function () {
  Firebase.child("Blogs").limitToLast(3).on("value",function(snap)
  {
    if(snap.val())
    {
      SetObj(snap.val())
    }
  })
  },[])
  return (
    <div>{/*Blog Two Start */}
      <section className="blog-two blog-three">
        <div className="container">
          <div className="section-title text-center sec-title-animation animation-style1">
            <div className="section-title__tagline-box">
              <p className="section-title__tagline">OUR LATEST BLOGS</p>
            </div>
            <h2 className="section-title__title title-animation">Read Our Latest Blog Post</h2>
          </div>
          <div className="row">
            {/*Blog Two Single Start*/}
            {
              Obj?Object.keys(Obj).map(function(key)
            {
              return(
                <div key={key} className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
              <div className="blog-two__single">
                <div className="blog-two__img-box">
                  <div className="blog-two__img">
                    <img style={{height:"200px"}} src={Obj[key].Heading_Image?Obj[key].Heading_Image:"assets/images/blog/blog-3-1.jpg"}  />
                  </div>
                  <div className="blog-two__date">
                    {/* <p><span>{Obj[key].Date?Obj[key].Date.split("/")[0]:""}</span><br />{Obj[key].Date?Obj[key].Date.split("/")[1]:""}</p> */}
                    <p><img style={{height:"60px",width:"60px",borderRadius:"50px",lineHeight:"2px"}} src={Obj[key].Author_Image?Obj[key].Author_Image:""}  /></p>
                  </div>
                </div>
                <div className="blog-two__content">
                  <h3 className="blog-two__title"><a onClick={()=>(navigate(`/BlogDetails/${key}`))}>{Obj[key].Heading?Obj[key].Heading:""}</a></h3>
                  <p className="blog-two__text">{Obj[key].Description?Obj[key].Description.split(" ").slice(0,20).map(line=>line+" "):""}...</p>
                  <div className="blog-two__btn-box text-center">
                    <a onClick={()=>(navigate(`/BlogDetails/${key}`))} style={{cursor:"pointer"}} className="blog-two__btn thm-btn btn btn-primary mybtns">READ MORE</a>
                  </div>
                </div>
              </div>
            </div>
              )
            }):""
            }
            {/*Blog Two Single End*/}
            {/*Blog Two Single Start*/}
            {/* <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
              <div className="blog-two__single">
                <div className="blog-two__img-box">
                  <div className="blog-two__img">
                    <img src="assets/images/blog/blog-3-2.jpg" alt />
                  </div>
                  <div className="blog-two__date">
                    <p><span>25</span><br />DEC</p>
                  </div>
                </div>
                <div className="blog-two__content">
                  <h3 className="blog-two__title"><a href="blog-details.html">insurance coverages that are
                    right</a></h3>
                  <p className="blog-two__text">Start a fast, free auto insurance qu with Alico. We help you
                    find any insurance coverages that are right</p>
                  <div className="blog-two__btn-box">
                    <a href="blog-details.html" className="blog-two__btn thm-btn">READ MORE</a>
                  </div>
                </div>
              </div>
            </div> */}
            {/*Blog Two Single End*/}
            {/*Blog Two Single Start*/}
            {/* <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="600ms">
              <div className="blog-two__single">
                <div className="blog-two__img-box">
                  <div className="blog-two__img">
                    <img src="assets/images/blog/blog-3-3.jpg" alt />
                  </div>
                  <div className="blog-two__date">
                    <p><span>25</span><br />DEC</p>
                  </div>
                </div>
                <div className="blog-two__content">
                  <h3 className="blog-two__title"><a href="blog-details.html">We help you find any
                    Insurance</a></h3>
                  <p className="blog-two__text">Start a fast, free auto insurance qu with Alico. We help you
                    find any insurance coverages that are right</p>
                  <div className="blog-two__btn-box">
                    <a href="blog-details.html" className="blog-two__btn thm-btn">READ MORE</a>
                  </div>
                </div>
              </div>
            </div> */}
            {/*Blog Two Single End*/}
          </div>
        </div>
      </section>
      {/*Blog Two End */}</div>
  )
}

export default HomeBlog