import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import MainSlider from '../Components/MainSlider'
import HomeAbout from '../Components/HomeAbout'
import HomeServices from '../Components/HomeServices'
import HomeCounter from '../Components/HomeCounter'
import HomeWhyChooseUs from '../Components/HomeWhyChooseUs'
import HomeOpenAccount from '../Components/HomeOpenAccount'
import HomeClientReview from '../Components/HomeClientReview'
import HomeQuote from '../Components/HomeQuote'
import HomeBlog from '../Components/HomeBlog'
 
import HomeFeatures from '../Components/HomeFeatures'
import ScrollToTop from '../Components/ScrollToTop'

const Home = () => {
  return (
    <div>
      <div>
        <div className="page-wrapper">
          <Header home="current"/>
          <MainSlider />
          <HomeAbout />
          <HomeServices />
          <HomeCounter />
          <HomeWhyChooseUs />
          <HomeOpenAccount />
          <HomeClientReview />
          <HomeQuote />
          {/* <HomeFeatures/> */}
          <HomeBlog />
          <Footer />
        </div>{/* /.page-wrapper */}
          <ScrollToTop/>
      </div>
{/*Scroll to top*/}
<div className="scroll-to-top">
  <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
  </svg>
</div>


    </div>
  )
}

export default Home