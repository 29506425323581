import React from 'react'
import CountUp from 'react-countup';
// import 'animate.css'; // Make sure to import animate.css for WOW.js or alternative
const HomeCounter = () => {
  return (
    <div>
        {/*Counter One Start */}
    {/* <section className="counter-one counter-two">
      <div className="counter-two__bg" style={{backgroundImage: 'url(assets/images/backgrounds/counter-two-bg.jpg)'}}>
      </div>
      <div className="container">
        <div className="counter-one__inner">
          <ul className="counter-one__count-list list-unstyled">
            <li className="wow fadeInLeft" data-wow-delay="100ms">
              <div className="counter-one__icon">
                <span className="icon-team" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text" data-stop={250} data-speed={1500}>00</h3>
                <span>+</span>
              </div>
              <p className="counter-one__text">Active Team Members</p>
            </li>
            <li className="wow fadeInLeft" data-wow-delay="300ms">
              <div className="counter-one__icon">
                <span className="icon-insurance" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text" data-stop={90} data-speed={1500}>00</h3>
                <span>+</span>
              </div>
              <p className="counter-one__text">Give Insurance</p>
            </li>
            <li className="wow fadeInRight" data-wow-delay="600ms">
              <div className="counter-one__icon">
                <span className="icon-rating" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text" data-stop="2.56" data-speed={1500}>00</h3>
                <span>k</span>
              </div>
              <p className="counter-one__text">Satisfied Customers</p>
            </li>
            <li className="wow fadeInRight" data-wow-delay="900ms">
              <div className="counter-one__icon">
                <span className="icon-team" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text" data-stop={99} data-speed={1500}>00</h3>
                <span>%</span>
              </div>
              <p className="counter-one__text">Awards Winning</p>
            </li>
          </ul>
        </div>
      </div>
    </section> */}
    {/*Counter One End */}
    <section className="counter-one counter-two">
      <div className="counter-two__bg" style={{ backgroundImage: 'url(assets/images/backgrounds/counter-two-bg.jpg)' }}>
      </div>
      <div className="container">
        <div className="counter-one__inner">
          <ul className="counter-one__count-list list-unstyled">
            <li className="wow fadeInLeft" data-wow-delay="100ms">
              <div className="counter-one__icon">
                <span className="icon-team" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text">
                  <CountUp start={0} end={5000} duration={1.5} />
                </h3>
                <span>+</span>
              </div>
              <p className="counter-one__text">People Insured</p>
            </li>
            <li className="wow fadeInLeft" data-wow-delay="300ms">
              <div className="counter-one__icon">
                <span className="icon-insurance" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text">
                  <CountUp start={0} end={23} duration={1.5} />
                </h3>
                <span>+</span>
              </div>
              <p className="counter-one__text">Years of Experience</p>
            </li>
            <li className="wow fadeInRight" data-wow-delay="600ms">
              <div className="counter-one__icon">
                <span className="icon-rating" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text">
                  <CountUp start={0} end={95} duration={1.5} />
                </h3>
                <span>%</span>
              </div>
              <p className="counter-one__text">Satisfied Customers</p>
            </li>
            <li className="wow fadeInRight" data-wow-delay="900ms">
              <div className="counter-one__icon">
                <span className="icon-team" />
              </div>
              <div className="counter-one__count count-box">
                <h3 className="count-text">
                  <CountUp start={0} end={200} duration={1.5} />
                </h3>
                <span>+</span>
              </div>
              <p className="counter-one__text">Professional Consultations</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
    </div>
  )
}

export default HomeCounter