import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
const MainSlider = () => {
  const options = {
    loop: true,
    items: 1,
    navText: ['<span class="icon-left-arrow"></span>', '<span class="icon-right-arrow"></span>'],
    margin: 0,
    dots: false,
    nav: false,
    animateOut: 'slideOutDown',
    animateIn: 'fadeIn',
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: false,
  };
  return (
    <div>
        {/* Main Sllider Start */}
    {/* <section className="main-slider-three">
      <div className="main-slider-three__carousel owl-carousel owl-theme thm-owl__carousel" data-owl-options="{&quot;loop&quot;: true, &quot;items&quot;: 1, &quot;navText&quot;: [&quot;<span class=\&quot;icon-left-arrow\&quot;></span>&quot;,&quot;<span class=\&quot;icon-right-arrow\&quot;></span>&quot;], &quot;margin&quot;: 0, &quot;dots&quot;: false, &quot;nav&quot;: false, &quot;animateOut&quot;: &quot;slideOutDown&quot;, &quot;animateIn&quot;: &quot;fadeIn&quot;, &quot;active&quot;: true, &quot;smartSpeed&quot;: 1000, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;autoplayHoverPause&quot;: false}">
        <div className="item main-slider-three__slide-1">
          <div className="main-slider-three__img">
            <img src="assets/images/resources/main-slider-three-img-1.png" alt className="float-bob-y" />
          </div>
          <div className="main-slider-three__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-three-shape-2.png" alt />
          </div>
          <div className="main-slider-three__shape-3 float-bob-x">
            <img src="assets/images/shapes/main-slider-three-shape-3.png" alt />
          </div>
          <div className="main-slider-three__shape-4 float-bob-y" />
          <div className="main-slider-three__shape-5 zoominout" />
          <div className="container">
            <div className="main-slider-three__content">
              <h2 className="main-slider-three__title">Secure <br /> Your <span className="main-slider-three__color-text">Future</span> <br />
                <span className="main-slider-three__color-text-two">Sheildign</span> Your <br />
                Risk Free
              </h2>
              <p className="main-slider-three__text">Phasellus placerat, ante sit amet sodales fermentum <br />
                urus lectus aliquam eros, elementum blandit</p>
              <div className="main-slider-three__btn-and-text-box">
                <a href="about.html" className="main-slider-three__btn thm-btn">get started</a>
                <p className="main-slider-three__text-box"> <span>$1000k</span> Up Coverage</p>
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider-three__slide-2">
          <div className="main-slider-three__img">
            <img src="assets/images/resources/main-slider-three-img-1.png" alt className="float-bob-y" />
          </div>
          <div className="main-slider-three__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-three-shape-2.png" alt />
          </div>
          <div className="main-slider-three__shape-3 float-bob-x">
            <img src="assets/images/shapes/main-slider-three-shape-3.png" alt />
          </div>
          <div className="main-slider-three__shape-4 float-bob-y" />
          <div className="main-slider-three__shape-5 zoominout" />
          <div className="container">
            <div className="main-slider-three__content">
              <h2 className="main-slider-three__title">Secure <br /> Your <span className="main-slider-three__color-text">Future</span> <br />
                <span className="main-slider-three__color-text-two">Sheildign</span> Your <br />
                Risk Free
              </h2>
              <p className="main-slider-three__text">Phasellus placerat, ante sit amet sodales fermentum <br />
                urus lectus aliquam eros, elementum blandit</p>
              <div className="main-slider-three__btn-and-text-box">
                <a href="about.html" className="main-slider-three__btn thm-btn">get started</a>
                <p className="main-slider-three__text-box"> <span>$1000k</span> Up Coverage</p>
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider-three__slide-3">
          <div className="main-slider-three__img">
            <img src="assets/images/resources/main-slider-three-img-1.png" alt className="float-bob-y" />
          </div>
          <div className="main-slider-three__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-three-shape-2.png" alt />
          </div>
          <div className="main-slider-three__shape-3 float-bob-x">
            <img src="assets/images/shapes/main-slider-three-shape-3.png" alt />
          </div>
          <div className="main-slider-three__shape-4 float-bob-y" />
          <div className="main-slider-three__shape-5 zoominout" />
          <div className="container">
            <div className="main-slider-three__content">
              <h2 className="main-slider-three__title">Secure <br /> Your <span className="main-slider-three__color-text">Future</span> <br />
                <span className="main-slider-three__color-text-two">Sheildign</span> Your <br />
                Risk Free
              </h2>
              <p className="main-slider-three__text">Phasellus placerat, ante sit amet sodales fermentum <br />
                urus lectus aliquam eros, elementum blandit</p>
              <div className="main-slider-three__btn-and-text-box">
                <a href="about.html" className="main-slider-three__btn thm-btn">get started</a>
                <p className="main-slider-three__text-box"> <span>$1000k</span> Up Coverage</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/*Main Sllider Start */}
    <section className="main-slider-three">
      <OwlCarousel className="main-slider-three__carousel owl-carousel owl-theme thm-owl__carousel" {...options}>
        <div className="item main-slider-three__slide-1">
          <div className="main-slider-three__img">
            <img src="assets/images/resources/main-slider-three-img-1.png" alt="slide" className="float-bob-y" />
          </div>
          <div className="main-slider-three__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-three-shape-2.png" alt="shape 2" />
          </div>
          <div className="main-slider-three__shape-3 float-bob-x">
            <img src="assets/images/shapes/main-slider-three-shape-3.png" alt="shape 3" />
          </div>
          <div className="main-slider-three__shape-4 float-bob-y" />
          <div className="main-slider-three__shape-5 zoominout" />
          <div className="container">
            <div className="main-slider-three__content">
              <h2 className="main-slider-three__title">
                Secure <br /> Your <span className="main-slider-three__color-text">Future</span> <br />
                <span className="main-slider-three__color-text-two">Shielding</span> Your <br />
                Risk Free
              </h2>
              <p className="main-slider-three__text">
               This emphasizes the importance of securing comprehensive <br /> insurance coverage, including health, life, and disability insurance, <br /> to safeguard against unforeseen financial setbacks.
              </p>
              <div className="main-slider-three__btn-and-text-box">
                <Link to="/About" className="main-slider-three__btn thm-btn">get started</Link>
                <p className="main-slider-three__text-box">
                  <span>₹1000k</span> Up Coverage
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider-three__slide-2">
          <div className="main-slider-three__img">
            <img src="assets/images/resources/main-slider-three-img-1.png" alt="slide" className="float-bob-y" />
          </div>
          <div className="main-slider-three__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-three-shape-2.png" alt="shape 2" />
          </div>
          <div className="main-slider-three__shape-3 float-bob-x">
            <img src="assets/images/shapes/main-slider-three-shape-3.png" alt="shape 3" />
          </div>
          <div className="main-slider-three__shape-4 float-bob-y" />
          <div className="main-slider-three__shape-5 zoominout" />
          <div className="container">
            <div className="main-slider-three__content">
              <h2 className="main-slider-three__title">
                Secure <br /> Your <span className="main-slider-three__color-text">Future</span> <br />
                <span className="main-slider-three__color-text-two">Shielding</span> Your <br />
                Risk Free
              </h2>
              <p className="main-slider-three__text">
              This emphasizes the importance of securing comprehensive <br /> insurance coverage, including health, life, and disability insurance, <br /> to safeguard against unforeseen financial setbacks.
              </p>
              <div className="main-slider-three__btn-and-text-box">
                <Link to="/About" className="main-slider-three__btn thm-btn">get started</Link>
                <p className="main-slider-three__text-box">
                  <span>₹1000k</span> Up Coverage
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider-three__slide-3">
          <div className="main-slider-three__img">
            <img src="assets/images/resources/main-slider-three-img-1.png" alt="slide" className="float-bob-y" />
          </div>
          <div className="main-slider-three__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-three-shape-2.png" alt="shape 2" />
          </div>
          <div className="main-slider-three__shape-3 float-bob-x">
            <img src="assets/images/shapes/main-slider-three-shape-3.png" alt="shape 3" />
          </div>
          <div className="main-slider-three__shape-4 float-bob-y" />
          <div className="main-slider-three__shape-5 zoominout" />
          <div className="container">
            <div className="main-slider-three__content">
              <h2 className="main-slider-three__title">
                Secure <br /> Your <span className="main-slider-three__color-text">Future</span> <br />
                <span className="main-slider-three__color-text-two">Shielding</span> Your <br />
                Risk Free
              </h2>
              <p className="main-slider-three__text">
              This emphasizes the importance of securing comprehensive <br /> insurance coverage, including health, life, and disability insurance, <br /> to safeguard against unforeseen financial setbacks.
              </p>
              <div className="main-slider-three__btn-and-text-box">
                <Link to="/About" className="main-slider-three__btn thm-btn">get started</Link>
                <p className="main-slider-three__text-box">
                  <span>₹1000k</span> Up Coverage
                </p>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
    </div>
  )
}

export default MainSlider