import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Firebase from '../Firebase'
import Context from '../Context'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BlogDetailComponent = () => {
const[Obj,SetObj]=useState("")
const {key}= useParams()
const {FetchBlogs}=useContext(Context)
useEffect(function()
{
    Firebase.child(`Blogs/${key}`).on("value",function(snap)
{
    if(snap.val())
    {
        SetObj(snap.val());
    }
})
},[])
async function copy()
{
    if (navigator.clipboard) {
       await navigator.clipboard.writeText("a2zinsurance.online").then(() => {
            toast.success('Link copied to clipboard')
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }
}
function New(key)
{
    Firebase.child(`Blogs/${key}`).on("value",function(snap)
{
    if(snap.val())
    {
        SetObj(snap.val());
    }
})
}
    return (
        <div>
            {/*Blog Details Start*/}
            <section className="blog-details">
            <ToastContainer position='top-right'/>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="blog-details__left">
                                <div className="blog-details__img-1">
                                    <img style={{height:"400px"}} src={Obj?.Heading_Image?Obj?.Heading_Image:"../assets/images/blog/blog-details-img-1.jpg"}  />
                                </div>
                                <div className="blog-details__content">
                                    <h3 className="blog-details__title-1">{Obj?.Heading?Obj?.Heading:"Heading"}</h3>
                                    {/* <ul className="list-unstyled blog-details__meta">
                                        <li><a><i className="fas fa-user-circle" />{Obj?.Author?Obj?.Author:""}</a>
                                        </li>
                                        <li><a href="blog-details.html"><i className="fas fa-comments" /> 2 Comments</a>
                                        </li>
                                    </ul> */}
                                    <p className="blog-details__text-1">{Obj.Description?Obj.Description:"Description"}
                                    </p>
                                </div>
                                <div className="blog-details__img-box">
                                    <div className="row">
                                        {
                                            Obj?.Images?Obj?.Images.map(function(obj,index)
                                        {
                                            return(
                                                <div key={index} className="col-xl-6 col-lg-6">
                                            <div className="blog-details__img-box-img">
                                                <img src={obj?.Path?obj?.Path:"../assets/images/blog/blog-details-img-box-img-2.jpg"}  />
                                            </div>
                                        </div>
                                            )
                                        }):""
                                        }
                                        {/* <div className="col-xl-6 col-lg-6">
                                            <div className="blog-details__img-box-img">
                                                <img src="../assets/images/blog/blog-details-img-box-img-1.jpg"  />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-xl-6 col-lg-6">
                                            <div className="blog-details__img-box-img">
                                                <img src="../assets/images/blog/blog-details-img-box-img-2.jpg"  />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <h2 className="blog-details__title-2">{Obj?.Title?Obj?.Title:"Title"}</h2>
                                <br />
                               <>
                               {
                                Obj?.Sub_head_desp?Obj?.Sub_head_desp.map(function(obj,index)
                            {
                                return(
                                    <div key={index}> <h3>{obj?.Sub_heading?obj?.Sub_heading:""}</h3>
                                    <p className="blog-details__text-2">{obj?.Sub_heading_description?obj?.Sub_heading_description:""}</p></div>
                                )
                            }):""
                               }
                               </>
                                {/* <div className="blog-details__quote-box">
                                    <div className="blog-details__quote-icon">
                                        <span className="icon-quote-1" />
                                    </div>
                                    <p className="blog-details__quote-text">Diam luctus nostra dapibus varius et semper semper
                                        rutrum ad risus
                                        <br /> eros. Cursus libero viverra tempus netus diam vestibulum</p>
                                    <span className="blog-details__quote-sub-title">David Backhum</span>
                                </div> */}
                                <div className="blog-details__tag-and-share">
                                    <div className="blog-details__tag">
                                        <span>Blog Category:</span>
                                        <a className='tagshover'>{Obj?.Category?Obj?.Category:""}</a>
                                    </div>
                                    <div className="blog-details__share">
                                        <a onClick={copy} className='tagshover'><span className="icon-share" /></a>
                                    </div>
                                </div>
                                {/* <div className="comment-one">
                                    <h3 className="comment-one__title">01 Comment</h3>
                                    <div className="comment-one__single">
                                        <div className="comment-one__image">
                                            <img src="../assets/images/blog/comment-1-1.jpg"  />
                                        </div>
                                        <div className="comment-one__content">
                                            <p className="comment-one__date">September 6, 2022 at 1:28 pm </p>
                                            <h3>Saiful Islam</h3>
                                            <p>Lorem ipsum dolor sit amet consectetur adipi vestibulum lectus egestas
                                                cubilia nam sagittis, nulla posuere habitant</p>
                                            <a href="blog-details.html" className="comment-one__btn"><span className="icon-reply" />Reply</a>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="comment-form">
                                    <h3 className="comment-one__title">Let’s Get in Touch</h3>
                                    <p>Your email address will not be published. Required fields are marked *</p>
                                    <form action="https://thememxpro.com/html/sonchoy/../assets/inc/sendemail.php" className="comment-one__form contact-form-validated" noValidate="novalidate">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box">
                                                    <input type="text" placeholder="Your Name*" name="name" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box">
                                                    <input type="email" placeholder="Your Email*" name="email" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="comment-form__input-box">
                                                    <input type="text" placeholder="Website*" name="email" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="comment-form__input-box text-message-box">
                                                    <textarea name="message" placeholder="Write Message*" defaultValue={""} />
                                                </div>
                                                <div className="comment-form__btn-box">
                                                    <button type="submit" className="thm-btn comment-form__btn">Send
                                                        Meassage</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="result" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="sidebar">
                                {/* <div className="sidebar__single sidebar__search">
                                    <h3 className="sidebar__title">Search</h3>
                                    <form action="#" className="sidebar__search-form">
                                        <input type="search" placeholder="Keywords here...." />
                                        <button type="submit"><i className="icon-search" /></button>
                                    </form>
                                </div> */}
                                <div className="sidebar__single sidebar__post">
                                    <h3 className="sidebar__title">Recently Post</h3>
                                    <ul className="sidebar__post-list list-unstyled">
                                        {
                                            FetchBlogs?Object.keys(FetchBlogs).map(function(key,index)
                                        {
                                            if(index<10)
                                            {
                                                return(
                                                    <li key={key}>
                                                    <div className="sidebar__post-image">
                                                        <img onClick={()=>New(key)} style={{cursor:"pointer"}} src={FetchBlogs[key]?.Heading_Image?FetchBlogs[key]?.Heading_Image:"../assets/images/blog/lp-1-1.jpg"}  />
                                                    </div>
                                                    <div className="sidebar__post-content">
                                                        <h3>
                                                            <a onClick={()=>New(key)} style={{cursor:"pointer"}}>{FetchBlogs[key]?.Title?FetchBlogs[key]?.Title:""}</a>
                                                            <span className="sidebar__post-content-meta"><i className="icon-clock" />{FetchBlogs[key]?.Date?FetchBlogs[key]?.Date:""}</span>
                                                        </h3>
                                                    </div>
                                                </li>
                                                )
                                            }
                                        }):""
                                        }
                                        {/* <li>
                                            <div className="sidebar__post-image">
                                                <img src="../assets/images/blog/lp-1-2.jpg"  />
                                            </div>
                                            <div className="sidebar__post-content">
                                                <h3>
                                                    <a href="blog-details.html">We’ve Been a Strategy
                                                        Thought Leader Nearly</a>
                                                    <span className="sidebar__post-content-meta"><i className="icon-clock" />April
                                                        21, 2023</span>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar__post-image">
                                                <img src="../assets/images/blog/lp-1-3.jpg"  />
                                            </div>
                                            <div className="sidebar__post-content">
                                                <h3>
                                                    <a href="blog-details.html">This Week’s Top Stories
                                                        About It</a>
                                                    <span className="sidebar__post-content-meta"><i className="icon-clock" />April
                                                        21, 2023</span>
                                                </h3>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="sidebar__single sidebar__category">
                                    <h3 className="sidebar__title">Categories</h3>
                                    <ul className="insurance-details__catagories-list list-unstyled">
                                        <li>
                                            <Link to="/HealthInsurance">Health Insurance<span className="icon-next" /></Link>
                                        </li>
                                        <li>
                                            <Link to="/HomeInsurance">Home Insurance<span className="icon-next" /></Link>
                                        </li>
                                        <li>
                                            <Link to="/LifeInsurance">Life Insurance <span className="icon-next" /></Link>
                                        </li>
                                        <li>
                                            <Link to="/CarInsurance">Car Insurance<span className="icon-next" /></Link>
                                        </li>
                                        <li>
                                            <Link to="/FamilyInsurance">Family Insurance<span className="icon-next" /></Link>
                                        </li>
                                        <li>
                                            <Link to="/BussinessInsurance">Business Insurance<span className="icon-next" /></Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="sidebar__single sidebar__tags">
                                    <h3 className="sidebar__title">Tags</h3>
                                    <div className="sidebar__tags-list">
                                    {
                                            Obj?.Tags?Obj?.Tags.split(",").map(function(tag)
                                        {
                                            return(
                                                <a className='tagshover' key={tag}>{tag}</a>
                                            )
                                        }):""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Blog Details End*/}

        </div>
    )
}

export default BlogDetailComponent