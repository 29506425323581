import React from 'react'
import Header from '../Components/Header'
 
import CommonBanner from '../Components/CommonBanner'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const FAQ = () => {
    return (
        <div>
            <Header faq="current"/>
            <CommonBanner Name={"Our Faq"} />
            <section className="faq-page">
                <div className="container">
                    <div className="faq-page__text-box">
                        <div className="faq-page__text-box-shape-1">
                            <img src="assets/images/shapes/faq-page-text-box-shape-1.png" />
                        </div>
                        <div className="section-titl text-left sec-titl-animation animation-style1">
                            <div className="section-title__tagline-box">
                                <p className="section-title__tagline">Our question and answer</p>
                            </div>
                            <h2 className="section-title__title titl-animation">Frequently asked question
                                <br /> &amp; answer here</h2>
                        </div>
                    </div>
                    <div className="faq-page__bottom">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="faq-page__bottom-right">
                                    <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                                        <div className="accrodion">
                                            <div className="accrodion-titl">
                                                <h4>What does a Health Insurance cover?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>A health insurance policy covers expenses that incurred due to the medical treatment/surgery. In order to get the benefits of the health plan, the policyholder has to go for at least 24 hours of continuous hospitalization. There are some day-care procedures that are also covered under a health plan, but you also need to get hospitalized to avail benefits.</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion  active">
                                            <div className="accrodion-titl">
                                                <h4>How much a Insurance Cover do I need?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Most of the insurance companies promote insurance cover of 10 times your annual income. That is the reason it has become a thumb rule. The minimum cover should be at least 15-20 times your annual income. Insurance companies also offer cover of 25 times your annual income</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion">
                                            <div className="accrodion-titl">
                                                <h4>Can i change my Insurance Coverage at any time ?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Yes, you can change your car insurance company at any time whenever you want but make sure to inform your current insurance company. To switch car insurance providers, compare car insurance quotes and choose the best option for your current situation.</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion">
                                            <div className="accrodion-titl">
                                                <h4>How long does it take to process an insurance claim?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>The time limit set for the claim settlement process by the IRDAI is within 30 days of raising the claim. Most insurance companies settle the claims within 10 days</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion">
                                            <div className="accrodion-titl">
                                                <h4>What happens if I'm in an accident with an uninsured driver?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Filing the FIR immediately after an accident is important because the insurer will require a copy of the FIR for filing third-party vehicle insurance claims. Therefore, if the at-fault driver is underinsured or uninsured, you must submit a copy of the FIR to their insurer</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion">
                                            <div className="accrodion-titl">
                                                <h4>What payment methods are available?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>You can pay the premium by several payment method like Paytm,GPay,Credit Card or Debit Card and by Cash also.</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="faq-page__bottom-right">
                                    <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                                        <div className="accrodion  active">
                                            <div className="accrodion-titl">
                                                <h4>Do I need to buy a Health Insurance policy even if I'm young and healthy?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Yes, it’s always advisable to buy a health insurance policy of adequate sum insured when you are young and in good health.
Having a health insurance policy, you won’t have to worry about the doctor’s fee and other medical expenses. Also, the possibility of any mishap or accident cannot be ruled out; it can happen to anybody, anywhere.</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion">
                                            <div className="accrodion-titl">
                                                <h4>What are TPA's?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>TPAs or third-party administrators are intermediaries between insurance companies, policyholders and hospitals. A TPA helps policyholder settle his/her claim hassle-free by establishing communication between the policyholder, the treating hospital and the insurer.</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion">
                                            <div className="accrodion-tite">
                                                <h4>Can i return my Health Insurance policy once bought?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Yes, you can return your health insurance policies within 15 days of receiving policy documents (called free-look period), mentioning the reason for your objection, if you disagree to any of the terms & conditions. As per IRDA’s guidelines, this feature is in policies for term of at least 3 years. However, insures can provide this facility even to the policies for lesser term. The refund premium will be adjusted for – expenses incurred in medical examination and stamp duty. In cases free-look period claims, claim amount will be deducted from the premium paid.</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                        <div className="accrodion">
                                            <div className="accrodion-titl">
                                                <h4>What is cashless Health Insurance Policy?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>A cashless health policy provides facility to get treatment at network hospitals (may vary from insurer to insurer) without making any payment. To avail this facility, the policyholder only needs to take approval from the insurance company/assigned TPA in advance in case of planned surgeries, and within the stipulated time in case of emergencies. Policyholders, in order to avail cashless treatment at network hospitals, need to produce health cards provided by insurers, with valid identity proofs to hospitals/TPAs.</p>
                                                </div>{/* /.inner */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        <ScrollToTop/>
        </div>
    )
}
export default FAQ