import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
const MainBannerTwo = () => {
  const options = {
    loop: true,
    items: 1,
    navText: ['<span class="icon-prev1"></span>', '<span class="icon-next"></span>'],
    margin: 0,
    dots:false,
    nav: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: false,
  };
  return (
    <div>
         {/* Main Sllider Start */}
    {/* <section className="main-slider">
      <div className="main-slider__carousel owl-carousel owl-theme thm-owl__carousel" data-owl-options="{&quot;loop&quot;: true, &quot;items&quot;: 1, &quot;navText&quot;: [&quot;<span class=\&quot;icon-prev1\&quot;></span>&quot;,&quot;<span class=\&quot;icon-next\&quot;></span>&quot;], &quot;margin&quot;: 0, &quot;dots&quot;: false, &quot;nav&quot;: true, &quot;animateOut&quot;: &quot;fadeOut&quot;, &quot;animateIn&quot;: &quot;fadeIn&quot;, &quot;false&quot;: true, &quot;smartSpeed&quot;: 1000, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;autoplayHoverPause&quot;: false}">
        <div className="item main-slider__slide-1">
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt />
          </div>
          <div className="main-slider__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt />
          </div>
          <div className="main-slider__shape-3 float-bob-y">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt />
          </div>
          <div className="main-slider__img">
            <img src="assets/images/resources/slider-1-1.png" alt />
          </div>
          <div className="container">
            <div className="main-slider__content">
              <div className="main-slider__section-and-text">
                <div className="section-title text-left sec-title-animation animation-style1">
                  <div className="section-title__tagline-box">
                    <p className="section-title__tagline">RELIABLE. pERSONABLE. fAST</p>
                  </div>
                  <h2 className="section-title__title title-animation">Life Insurance
                    <br /> That Creates
                    <br /> Future</h2>
                </div>
                <p className="main-slider__text">Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames
                  <br /> ac turpis egestas. Proin elementum finibus lacus quis hendrerit.</p>
              </div>
              <div className="main-slider__btn-and-ratting-box">
                <div className="main-slider__btn-box">
                  <Link to="/About" className="main-slider__btn thm-btn">get started</Link>
                </div>
                <div className="main-slider__ratting">
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <span>Rated 5/5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider__slide-1">
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt />
          </div>
          <div className="main-slider__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt />
          </div>
          <div className="main-slider__shape-3 float-bob-y">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt />
          </div>
          <div className="main-slider__img">
            <img src="assets/images/resources/slider-1-1.png" alt />
          </div>
          <div className="container">
            <div className="main-slider__content">
              <div className="main-slider__section-and-text">
                <div className="section-title text-left sec-title-animation animation-style1">
                  <div className="section-title__tagline-box">
                    <p className="section-title__tagline">RELIABLE. pERSONABLE. fAST</p>
                  </div>
                  <h2 className="section-title__title title-animation">Life Insurance
                    <br /> That Creates
                    <br /> Future</h2>
                </div>
                <p className="main-slider__text">Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames
                  <br /> ac turpis egestas. Proin elementum finibus lacus quis hendrerit.</p>
              </div>
              <div className="main-slider__btn-and-ratting-box">
                <div className="main-slider__btn-box">
                  <Link to="/About" className="main-slider__btn thm-btn">get started</Link>
                </div>
                <div className="main-slider__ratting">
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <span>Rated 5/5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider__slide-1">
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt />
          </div>
          <div className="main-slider__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt />
          </div>
          <div className="main-slider__shape-3 float-bob-y">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt />
          </div>
          <div className="main-slider__img">
            <img src="assets/images/resources/slider-1-1.png" alt />
          </div>
          <div className="container">
            <div className="main-slider__content">
              <div className="main-slider__section-and-text">
                <div className="section-title text-left sec-title-animation animation-style1">
                  <div className="section-title__tagline-box">
                    <p className="section-title__tagline">RELIABLE. pERSONABLE. fAST</p>
                  </div>
                  <h2 className="section-title__title title-animation">Life Insurance
                    <br /> That Creates
                    <br /> Future</h2>
                </div>
                <p className="main-slider__text">Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames
                  <br /> ac turpis egestas. Proin elementum finibus lacus quis hendrerit.</p>
              </div>
              <div className="main-slider__btn-and-ratting-box">
                <div className="main-slider__btn-box">
                  <Link to="/About" className="main-slider__btn thm-btn">get started</Link>
                </div>
                <div className="main-slider__ratting">
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <span>Rated 5/5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/*Main Sllider Start */}
    <section className="main-slider">
      <OwlCarousel className="main-slider__carousel owl-carousel owl-theme thm-owl__carousel" {...options}>
        <div className="item main-slider__slide-1">
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="shape 1" />
          </div>
          <div className="main-slider__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="shape 2" />
          </div>
          <div className="main-slider__shape-3 float-bob-y">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="shape 3" />
          </div>
          <div className="main-slider__img">
            <img src="assets/images/resources/slider-1-1.png" alt="slider 1" />
          </div>
          <div className="container">
            <div className="main-slider__content">
              <div className="main-slider__section-and-text">
                <div className="section-title text-left sec-title-animation animation-style1">
                  <div className="section-title__tagline-box">
                    <p className="section-title__tagline">RELIABLE. PERSONABLE. FAST</p>
                  </div>
                  <h2 className="section-title__title title-animation">
                    Life Insurance
                    <br /> That Creates
                    <br /> Future
                  </h2>
                </div>
                <p className="main-slider__text">
               This emphasizes the importance of securing comprehensive <br /> insurance coverage, including health, life, and disability insurance, <br /> to safeguard against unforeseen financial setbacks.
                 
                </p>
              </div>
              <div className="main-slider__btn-and-ratting-box">
                <div className="main-slider__btn-box">
                  <Link to="/About" className="main-slider__btn thm-btn">get started</Link>
                </div>
                {/* <div className="main-slider__ratting">
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <span>Rated 5/5</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider__slide-1">
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="shape 1" />
          </div>
          <div className="main-slider__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="shape 2" />
          </div>
          <div className="main-slider__shape-3 float-bob-y">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="shape 3" />
          </div>
          <div className="main-slider__img">
            <img src="assets/images/resources/slider-1-1.png" alt="slider 1" />
          </div>
          <div className="container">
            <div className="main-slider__content">
              <div className="main-slider__section-and-text">
                <div className="section-title text-left sec-title-animation animation-style1">
                  <div className="section-title__tagline-box">
                    <p className="section-title__tagline">RELIABLE. PERSONABLE. FAST</p>
                  </div>
                  <h2 className="section-title__title title-animation">
                    Life Insurance
                    <br /> That Creates
                    <br /> Future
                  </h2>
                </div>
                <p className="main-slider__text">
               This emphasizes the importance of securing comprehensive <br /> insurance coverage, including health, life, and disability insurance, <br /> to safeguard against unforeseen financial setbacks.
                </p>
              </div>
              <div className="main-slider__btn-and-ratting-box">
                <div className="main-slider__btn-box">
                  <Link to="/About" className="main-slider__btn thm-btn">get started</Link>
                </div>
                {/* <div className="main-slider__ratting">
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <span>Rated 5/5</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="item main-slider__slide-1">
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="shape 1" />
          </div>
          <div className="main-slider__shape-2 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="shape 2" />
          </div>
          <div className="main-slider__shape-3 float-bob-y">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="shape 3" />
          </div>
          <div className="main-slider__img">
            <img src="assets/images/resources/slider-1-1.png" alt="slider 1" />
          </div>
          <div className="container">
            <div className="main-slider__content">
              <div className="main-slider__section-and-text">
                <div className="section-title text-left sec-title-animation animation-style1">
                  <div className="section-title__tagline-box">
                    <p className="section-title__tagline">RELIABLE. PERSONABLE. FAST</p>
                  </div>
                  <h2 className="section-title__title title-animation">
                    Life Insurance
                    <br /> That Creates
                    <br /> Future
                  </h2>
                </div>
                <p className="main-slider__text">
               This emphasizes the importance of securing comprehensive <br /> insurance coverage, including health, life, and disability insurance, <br /> to safeguard against unforeseen financial setbacks.
                </p>
              </div>
              <div className="main-slider__btn-and-ratting-box">
                <div className="main-slider__btn-box">
                  <Link to="/About" className="main-slider__btn thm-btn">get started</Link>
                </div>
                {/* <div className="main-slider__ratting">
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <i className="icon-star-1" />
                  <span>Rated 5/5</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Repeat similar blocks for other slides */}
      </OwlCarousel>
    </section>
    </div>
  )
}

export default MainBannerTwo