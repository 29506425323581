import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import CommonBanner from '../Components/CommonBanner'
import BlogDetailComponent from '../Components/BlogDetailComponent'
import "../assets/vendors/animate/animate.min.css"
import "../assets/vendors/animate/custom-animate.css"
import "../assets/vendors/fontawesome/css/all.min.css"
import "../assets/vendors/jarallax/jarallax.css"
import "../assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css"
import "../assets/vendors/odometer/odometer.min.css"
import "../assets/vendors/swiper/swiper.min.css"
import "../assets/vendors/sonchoy-icon/style.css"
import "../assets/vendors/owl-carousel/owl.carousel.min.css"
import "../assets/vendors/owl-carousel/owl.theme.default.min.css"
import "../assets/vendors/bootstrap-select/css/bootstrap-select.min.css"
import "../assets/vendors/jquery-ui/jquery-ui.css"
import "../assets/vendors/nice-select/nice-select.css"
import "../assets/css/sonchoy.css" 
import "../assets/css/color-4.css"
import "../assets/css/sonchoy-responsive.css"
import ScrollToTop from '../Components/ScrollToTop'
const BlogDetails = () => {
  return (
    <div>
        <Header blog="current"/>
        <CommonBanner Name={"Blog Details"} />
        <BlogDetailComponent/>
        <Footer/>
        <ScrollToTop/>
    </div>
  )
}
export default BlogDetails