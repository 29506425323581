import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Firebase from "../Firebase"
import emailjs from "@emailjs/browser"
const ContactComponent = () => {
    const[Obj,SetObj]=useState({
        Name:"",
        Phone:"",
        Email:"",
        Message:""
    })
    const[Error,SetError]=useState(true)
    const navigate=useNavigate()
    const d=new Date()
    const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
    useEffect(function()
    {
        emailjs.init("ehUfdXxMi8tVINNKj");
    },[])
    function set(event)
    {   
        SetObj({...Obj,[event.target.name]:event.target.value.toLowerCase(),"Date":date})
    }
    function EmailChange(email){
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email)
      }
      function NameChange(event){
        const name = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        SetObj({...Obj,"Name":name.toLowerCase()});
      }
      function PhoneNumberChange(event)
      {
        const phone = event.target.value.trim().replace(/[^\d-]/g, '');
        SetObj({...Obj,"Phone":phone.toLowerCase()})
      }
    function save(e)
    {
        e.preventDefault()
        if(Obj.Name!="" && Obj.Phone!="" && Obj.Email!="" && Obj.Message!="")
            {
               const response= EmailChange(Obj.Email)
               if(response)
               {
                SetError(true)
                Firebase.child("Contacts").push(Obj,async(err)=>{
                if(!err)
                {
                    toast.success("Thank you for contacting us. We will contact you soon.")
                    await EmailSend(Obj)
                    setTimeout(() => {
                        navigate("/")
                    }, 5000);
                }
                else
                {
                    toast.error("Error Occured.Try again later")
                }
                })
               }
               else
               {
                SetError(false)
                toast.error("Please enter a valid email address")
               }
            }
            else
            {
                toast.warning("Field is Empty")
            }
    }
    async function EmailSend(Obj)
    {
        const ServiceID="service_h4xhf3p"
		const TemplateID="template_w19t30k"
	    await emailjs.send(ServiceID,TemplateID,Obj).then((resp)=>{
		console.log("Email Send")
		}).catch((err)=>{
		console.log(err);
		})
    }
  return (
    <div> {/*Contact Page Start*/}
    <section className="contact-page">
        <div className="container">
        <ToastContainer position='top-center'/>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="contact-page__left">
                        <h3 className="contact-page__title">Connect with us for expert advice</h3>
                        <p className="contact-page__text">We are here to help and give you best advice and helps in every step of the how will you get the insurance? Their benefits? and Their coverage? and many more. We ensure that the services provided by us is the best service.</p>
                        <div className="contact-page__contact-info">
                            <h4 className="contact-page__contact-info-title">Contact Info</h4>
                            <ul className="contact-page__contact-list list-unstyled">
                                <li>
                                    <h5>Address</h5>
                                    <p>Chaman Medicos,Mata Darwaja Chowk, Rohtak-124001</p>
                                </li>
                                <li>
                                    <h5>Phone</h5>
                                    <p><a href="tel:+919255433099"> +91 9255433099</a></p>
                                </li>
                                <li>
                                    <h5>Email</h5>
                                    <p><a href="mailto:A2ZSolutionsRTK@gmail.com">A2ZSolutionsRTK@gmail.com</a></p>
                                </li>
                            </ul>
                            {/* <a href="#" className="contact-page__link">www.example.com</a> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="contact-page__right">
                        <div className="contact-page__img">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d111770.317035707!2d76.572746!3d28.903625000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d85955835d6d7%3A0x9beb3ac259119d65!2sChaman%20Medicose!5e0!3m2!1sen!2sin!4v1716663601724!5m2!1sen!2sin" width="600" height="550" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-page__bottom">
                <div className="comment-form">
                    <h3 className="comment-one__title">Let’s Get in Touch</h3>
                    <p>Your details will not be published anywhere and are kept secret*</p>
                    <form className="comment-one__form contact-form-validated" noValidate="novalidate">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="comment-form__input-box">
                                    <input type="text" onChange={NameChange} value={Obj.Name} placeholder="Enter your Name*" name="Name" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="comment-form__input-box">
                                    <input style={{backgroundColor:"white"}} value={Obj.Phone} onChange={PhoneNumberChange} maxLength={10} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" type="tel" placeholder="Enter your Phone Number*" name="Phone" />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="comment-form__input-box">
                                    <input type="email" onChange={set} placeholder="Enter your Email*" name="Email" />
                                    {!Error && <p style={{ color: 'red' }}>Please enter a valid email address</p>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="comment-form__input-box text-message-box">
                                    <textarea name="Message" onChange={set} placeholder="Write Message*" defaultValue={""} />
                                </div>
                                <div className="comment-form__btn-box">
                                    <button onClick={save} type="submit" className="thm-btn comment-form__btn">Send
                                        Message</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="result" />
                </div>
            </div>
        </div>
    </section>
    {/*Contact Page End*/}</div>
  )
}

export default ContactComponent