import React from 'react'
import InsuranceCategories from './InsuranceCategories'

const HealthInsuranceComponent = () => {
  return (
    <div>
     {/*Insurance Details Start*/}
<section className="insurance-details">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-7">
        <div className="insurance-details__left">
          <h3 className="insurance-details__title-1">Protect Your Future: Health Insurance That Works for You</h3>
          <p className="insurance-details__text-1">Your health is your most valuable asset, and ensuring access to quality healthcare is essential for your well-being and peace of mind. However, navigating the complexities of healthcare costs and medical expenses can be daunting. That's where health insurance comes in. Our comprehensive health insurance solutions are designed to provide you and your family with the financial protection and support you need to address your healthcare needs. From covering routine check-ups to unforeseen medical emergencies, our tailored plans offer peace of mind, ensuring that you can focus on what matters most—your health.
          </p>
          <div className="insurance-details__img-1">
            <img src="assets/images/services/insurance-details-img-5.jpg"/>
          </div>
          <h3 className="insurance-details__title-2">Healthcare Shield: Reliable Insurance for Your Medical Needs</h3>
          <p className="insurance-details__text-2">Health insurance offers essential protection for your well-being, providing financial coverage for medical expenses and ensuring access to quality healthcare services. Our comprehensive plans are designed to meet your unique needs, offering peace of mind and security for you and your family. With reliable coverage and personalized support, you can rest assured knowing that your health is in good hands. Invest in your well-being today with our trusted health insurance solutions.</p>
          <div className="insurance-details__points-and-text-box">
            <div className="insurance-details__points-box">
              <h3 className="insurance-details__points-title">Our Goals</h3>
              <ul className="insurance-details__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Access to Healthcare</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Preventive Care</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Chronic Disease Management</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Emergency Medical Services</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Health and Wellness Promotion</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Mental Health and Behavioral Health Services</p>
                </li>
              </ul>
            </div>
            <div className="insurance-details__text-box">
              <h3>The Challenges</h3>
              <p>Health insurance plans often have <br /> provider networks, requiring individuals <br /> to seek care from in-network providers <br /> to receive full coverage. Limited <br /> network options or difficulties finding <br /> in-network providers can pose <br /> challenges for accessing care.</p>
            </div>
          </div>
          <div className="insurance-details__faq">
            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What is health insurance? Why do I need health insurance? What does health insurance cover?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Health insurance is a type of insurance policy that helps cover the costs of medical and surgical expenses incurred by the insured, providing financial protection against unexpected healthcare expenses.It is essential for protecting you and your family from the high costs of medical care, including hospitalization, surgeries, prescription medications, and preventive services. It provides financial security and access to quality healthcare when needed. And it  typically covers a range of medical expenses, including doctor visits, hospital stays, emergency care, prescription drugs, laboratory tests, preventive services, and mental health services, depending on the specific policy and coverage options.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion  active">
                <div className="accrodion-title">
                  <h4>How does health insurance work?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Health insurance works by pooling risk among a large group of individuals, with premiums paid by policyholders contributing to a fund that is used to pay for medical expenses incurred by members of the group. Policyholders receive coverage for eligible medical expenses according to the terms of their insurance policy.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What types of health insurance plans are available?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>here are several types of health insurance plans available, including Health Maintenance Organization (HMO) plans, Preferred Provider Organization (PPO) plans, Exclusive Provider Organization (EPO) plans, and Point of Service (POS) plans, each with different networks, coverage options, and cost-sharing arrangements.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>Is there a penalty for not having health insurance? Can I stay on my parents' health insurance plan?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>The Affordable Care Act (ACA) implemented an individual mandate requiring most Americans to have health insurance coverage or pay a penalty, known as the individual shared responsibility payment. However, the penalty was reduced to zero starting in 2019.
                    Under the ACA, young adults can stay on their parents' health insurance plan until age 26, regardless of their marital or student status, as long as the plan offers dependent coverage.
                    </p>
                  </div>{/* /.inner */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <InsuranceCategories Health="active"/>
    </div>
  </div>
</section>
{/*Insurance Details End*/}

    </div>
  )
}

export default HealthInsuranceComponent