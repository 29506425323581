import React from 'react'

const CommonBanner = (props) => {
  return (
    <div>
        {/*Page Header Start*/}
    <section className="page-header">
      <div className="page-header__bg" style={{backgroundImage: 'url(../assets/images/backgrounds/page-header-bg.jpg)'}}>
      </div>
      <div className="container">
        <div className="page-header__inner">
          <h2>{props.Name}</h2>
          <div className="thm-breadcrumb__box">
            <ul className="thm-breadcrumb list-unstyled">
              <li><a href="index.html">Home</a></li>
              <li><span>-</span></li>
              <li>{props.Name}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    {/*Page Header End*/}
    </div>
  )
}

export default CommonBanner