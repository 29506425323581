import React from 'react'
import InsuranceCategories from './InsuranceCategories'

const HomeInsuranceComponent = () => {
  return (
    <div>
        {/*Insurance Details Start*/}
<section className="insurance-details">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-7">
        <div className="insurance-details__left">
          <h3 className="insurance-details__title-1">Secure Your Sanctuary: Essential Home Insurance Coverage</h3>
          <p className="insurance-details__text-1">Your home is more than just a physical structure—it's where memories are made, where your family finds comfort, and where you feel most secure. However, unforeseen events like natural disasters, accidents, or theft can disrupt the safety and stability of your haven. That's where home insurance comes in. Our comprehensive home insurance solutions are designed to protect your dwelling, belongings, and peace of mind against life's uncertainties. From covering structural damage to providing financial assistance in the event of a liability claim, our tailored policies ensure that your home remains a sanctuary for you and your loved ones. Invest in the security and well-being of your home today with our trusted insurance coverage.
          </p>
          <div className="insurance-details__img-1">
            <img src="assets/images/services/insurance-details-img-6.jpg" alt />
          </div>
          <h3 className="insurance-details__title-2">Care for Your Property: Family-Focused Home Insurance Solutions</h3>
          <p className="insurance-details__text-2">Home insurance provides essential protection for your residence and belongings, offering peace of mind and financial security against unforeseen events. Our comprehensive policies are tailored to safeguard your home against risks such as fire, theft, natural disasters, and liability claims. With reliable coverage and personalized service, we're here to ensure that your home remains a sanctuary for you and your family. Protect your most valuable asset with our trusted home insurance solutions.</p>
          <div className="insurance-details__points-and-text-box">
            <div className="insurance-details__points-box">
              <h3 className="insurance-details__points-title">Our Goals</h3>
              <ul className="insurance-details__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Belongings Coverage</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Liability Protection</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Additional Living Expenses</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Mortgage Protection</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Risk Mitigation</p>
                </li>
              </ul>
            </div>
            <div className="insurance-details__text-box">
              <h3>The Challenges</h3>
              <p>Home insurance premiums can be expensive, particularly <br /> in areas prone to natural disasters or high crime rates, <br /> making it challenging for homeowners to afford adequate <br /> coverage.
              Fluctuations in property values, renovations, <br /> or improvements to the home may require adjustments <br /> to insurance coverage, which can be challenging to <br /> keep up with over time.</p>
            </div>
          </div>
          <div className="insurance-details__faq">
            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What is home insurance? and Why do I need home insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Home insurance is a type of insurance policy that provides financial protection for your home and personal belongings against covered perils such as fire, theft, vandalism, and certain natural disasters. Home insurance is essential for protecting your most valuable asset—your home—from unforeseen events that could result in significant financial losses. It also provides liability coverage and peace of mind knowing that you're financially protected.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion  active">
                <div className="accrodion-title">
                  <h4>What does home insurance cover?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Home insurance typically covers the structure of your home, personal belongings, liability for injuries or property damage to others, and additional living expenses if your home becomes uninhabitable due to a covered loss.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What factors affect home insurance premiums?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Several factors can affect home insurance premiums, including the location and age of your home, its construction materials, the coverage limits and deductibles you choose, your claims history, and any additional coverage options you select.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What types of coverage options are available with home insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Home insurance policies typically include dwelling coverage, personal property coverage, liability coverage, and additional living expenses coverage. You may also have options for additional coverage such as flood insurance, earthquake insurance, or scheduled personal property coverage.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <InsuranceCategories Home="active"/>
    </div>
  </div>
</section>
{/*Insurance Details End*/}

    </div>
  )
}

export default HomeInsuranceComponent