import React from 'react'
 
import Header from '../Components/Header'
import CommonBanner from '../Components/CommonBanner'
import HomeFeatures from '../Components/HomeFeatures'
import HomeAbout from '../Components/HomeAbout'
import HomeCounter from '../Components/HomeCounter'
import HomeClientReview from '../Components/HomeClientReview'
import Footer from '../Components/Footer'
import Team from '../Components/Team'
import ScrollToTop from '../Components/ScrollToTop'

const About = () => {
  return (
    <div>
    <div>
  <div className="page-wrapper">
    <Header about="current"/>
    <CommonBanner Name={"About Us"} />
    <HomeFeatures/>
    <HomeAbout/>
    <HomeCounter/>
    <HomeClientReview/>
    {/* <Team/>  */}
    <Footer/>
  </div>{/* /.page-wrapper */}
  <ScrollToTop/>
  {/*Scroll to top*/}
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</div>
    </div>
  )
}

export default About