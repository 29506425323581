import React from 'react'
 
import Header from '../Components/Header'
import CommonBanner from '../Components/CommonBanner'
import Footer from '../Components/Footer'
import ContactComponent from '../Components/ContactComponent'
import ScrollToTop from '../Components/ScrollToTop'
// import "../assets/vendors/animate/animate.min.css"
// import "../assets/vendors/animate/custom-animate.css"
// import "../assets/vendors/fontawesome/css/all.min.css"
// import "../assets/vendors/jarallax/jarallax.css"
// import "../assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css"
// import "../assets/vendors/odometer/odometer.min.css"
// import "../assets/vendors/swiper/swiper.min.css"
// import "../assets/vendors/sonchoy-icon/style.css"
// import "../assets/vendors/owl-carousel/owl.carousel.min.css"
// import "../assets/vendors/owl-carousel/owl.theme.default.min.css"
// import "../assets/vendors/bootstrap-select/css/bootstrap-select.min.css"
// import "../assets/vendors/jquery-ui/jquery-ui.css"
// import "../assets/vendors/nice-select/nice-select.css"

const Contact = () => {
    return (
        <div>
            <div>
                <div className="page-wrapper">
                    <Header contact="current"/>
                    <CommonBanner Name={"Contact Us"} />
                   <ContactComponent/>
                   <Footer/>
                   <ScrollToTop/>
                </div>{/* /.page-wrapper */}
                <div className="scroll-to-top">
                    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                    </svg>
                </div>
            </div>

        </div>
    )
}

export default Contact