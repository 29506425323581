import React from 'react'
import CountUp from 'react-countup';
const HomeWhyChooseUs = () => {
  return (
    <div>
        {/*Why Choose One Start */}
    <section className="why-choose-one">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="why-choose-one__left">
              <div className="why-choose-one__img-box">
                <div className="why-choose-one__img">
                  <img src="assets/images/resources/why-choose-one-img-1.jpg"  />
                </div>
                <div className="why-choose-one__img-2">
                  <img src="assets/images/resources/why-choose-one-img-2.jpg"  />
                  <div className="why-choose-one__experience-box">
                  <div className="why-choose-one__count count-box">
          <h3 className="count-text">
            <CountUp start={0} end={23} duration={1.5} />
          </h3>
        </div>
                    <p>YEARS
                      <br /> WORKING
                      <br /> EXPERIENCE</p>
                  </div>
                </div>
                <div className="why-choose-one__img-3">
                  <img src="assets/images/resources/why-choose-one-img-3.jpg"  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="why-choose-one__right">
              <div className="section-title text-left sec-title-animation animation-style1">
                <div className="section-title__tagline-box">
                  <p className="section-title__tagline">WHY CHOOSE A2Z Solution?</p>
                </div>
                <h2 className="section-title__title title-animation">What Makes Us
                  <br /> Different From Others</h2>
              </div>
              <ul className="why-choose-one__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-save-money-1" />
                  </div>
                  <div className="content">
                    <h3>Financial Security</h3>
                    <p>Life insurance safeguards your family & your financial requirements in event of a tragidy or untimely death.</p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-cashback" />
                  </div>
                  <div className="content">
                    <h3>Worldwide Coverage</h3>
                    <p>It is a global policy provided by companies that covers your business or individual against loss or damage, globally.</p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-bar-chart" />
                  </div>
                  <div className="content">
                    <h3>Easy to claim insurance</h3>
                    <p>For cashless claim, you will directly settle the bills with the network service providers.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*Why Choose One End */}
    </div>
  )
}

export default HomeWhyChooseUs