import React from 'react'
import { Link } from 'react-router-dom'

const InsuranceComponent = () => {
  return (
    <div>
      {/*Insurence Page Start*/}
<section className="insurence-page">
  <div className="container">
    <div className="row">
      {/*Services One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="services-one__single">
          <div className="services-one__title-box">
            <h3 className="services-one__title"><Link to="/LifeInsurance">Life Insurance</Link>
            </h3>
          </div>
          <div className="services-one__img-box">
            <div className="services-one__img">
              <img src="assets/images/services/insurence-1-1.jpg"  />
            </div>
            <div className="services-one__icon">
              <span className="icon-family-insurance" />
            </div>
          </div>
          <div className="services-one__read-more">
            <Link to="/LifeInsurance">READ MORE<span className="icon-next" /></Link>
          </div>
        </div>
      </div>
      {/*Services One Single End*/}
      {/*Services One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="services-one__single">
          <div className="services-one__title-box">
            <h3 className="services-one__title"><Link to="/HealthInsurance">Health Insurance</Link>
            </h3>
          </div>
          <div className="services-one__img-box">
            <div className="services-one__img">
              <img src="assets/images/services/insurence-1-2.jpg"  />
            </div>
            <div className="services-one__icon">
              <span className="icon-healthcare" />
            </div>
          </div>
          <div className="services-one__read-more">
            <Link to="/HealthInsurance">READ MORE<span className="icon-next" /></Link>
          </div>
        </div>
      </div>
      {/*Services One Single End*/}
      {/*Services One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="services-one__single">
          <div className="services-one__title-box">
            <h3 className="services-one__title"><Link to="/BusinessInsurance">Business Insurance</Link>
            </h3>
          </div>
          <div className="services-one__img-box">
            <div className="services-one__img">
              <img src="assets/images/services/insurence-1-3.jpg"  />
            </div>
            <div className="services-one__icon">
              <span className="icon-risk-management" />
            </div>
          </div>
          <div className="services-one__read-more">
            <Link to="/BusinessInsurance">READ MORE<span className="icon-next" /></Link>
          </div>
        </div>
      </div>
      {/*Services One Single End*/}
      {/*Services One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="services-one__single">
          <div className="services-one__title-box">
            <h3 className="services-one__title"><Link to="/CarInsurance">Car Insurance</Link>
            </h3>
          </div>
          <div className="services-one__img-box">
            <div className="services-one__img">
              <img src="assets/images/services/insurence-1-4.jpg"  />
            </div>
            <div className="services-one__icon">
              <span className="icon-car-1" />
            </div>
          </div>
          <div className="services-one__read-more">
            <Link to="/CarInsurance">READ MORE<span className="icon-next" /></Link>
          </div>
        </div>
      </div>
      {/*Services One Single End*/}
      {/*Services One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="services-one__single">
          <div className="services-one__title-box">
            <h3 className="services-one__title"><Link to="/HomeInsurance">Home Insurance</Link>
            </h3>
          </div>
          <div className="services-one__img-box">
            <div className="services-one__img">
              <img src="assets/images/services/insurence-1-5.jpg"  />
            </div>
            <div className="services-one__icon">
              <span className="icon-home-security" />
            </div>
          </div>
          <div className="services-one__read-more">
            <Link to="/HomeInsurance">READ MORE<span className="icon-next" /></Link>
          </div>
        </div>
      </div>
      {/*Services One Single End*/}
      {/*Services One Single Start*/}
      <div className="col-xl-4 col-lg-4">
        <div className="services-one__single">
          <div className="services-one__title-box">
            <h3 className="services-one__title"><Link to="/FamilyInsurance">Family Insurance</Link>
            </h3>
          </div>
          <div className="services-one__img-box">
            <div className="services-one__img">
              <img src="assets/images/services/insurence-1-6.jpg"  />
            </div>
            <div className="services-one__icon">
              <span className="icon-family-insurance" />
            </div>
          </div>
          <div className="services-one__read-more">
            <Link to="/FamilyInsurance">READ MORE<span className="icon-next" /></Link>
          </div>
        </div>
      </div>
      {/*Services One Single End*/}
    </div>
  </div>
</section>
{/*Insurence Page End*/}

    </div>
  )
}

export default InsuranceComponent