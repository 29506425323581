import React from 'react'
import Header from '../Components/Header'
import MainBannerTwo from '../Components/MainBannerTwo'
import HomeAbout from '../Components/HomeAbout'
import HomeCounter from '../Components/HomeCounter'
import HomeServices from '../Components/HomeServices'
import HomeWhyChooseUs from '../Components/HomeWhyChooseUs'
import HomeQuote from '../Components/HomeQuote'
import HomeClientReview from '../Components/HomeClientReview'
import HomeOpenAccount from '../Components/HomeOpenAccount'
import HomeBlog from '../Components/HomeBlog'
import Footer from '../Components/Footer'
 
import HomeFeatures from '../Components/HomeFeatures'
import ScrollToTop from '../Components/ScrollToTop'

const HomeTwo = () => {
  return (
    <div>
<div>
   
  <div className="page-wrapper">
    <Header/>
    <MainBannerTwo/>
    <HomeAbout/>
    <HomeCounter/>
    <HomeServices/>
    <HomeWhyChooseUs/>
    <HomeOpenAccount/>
    <HomeQuote/>
    <HomeFeatures/>
    <HomeClientReview/>
    <HomeBlog/>
    <Footer/>
    <ScrollToTop/>
  </div>{/* /.page-wrapper */}
  {/*Scroll to top*/}
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</div>

    </div>
  )
}

export default HomeTwo