import React, { useEffect, useState } from 'react'
import Home from './Pages/Home'
import { BrowserRouter as Router , Route, Routes, Navigate } from 'react-router-dom';
import './assets/vendors/bootstrap/css/bootstrap.min.css'
import About from './Pages/About';
import HomeTwo from './Pages/HomeTwo';
import Contact from './Pages/Contact';
import Testimonials from './Pages/Testimonials';
import FAQ from './Pages/FAQ';
import ErrorHandel from './Pages/ErrorHandel';
import Insurance from './Pages/Insurance';
import Blog from './Pages/Blog';
import BlogDetails from './Pages/BlogDetails';
import LifeInsurance from './Pages/LifeInsurance';
import HealthInsurance from './Pages/HealthInsurance';
import CarInsurance from './Pages/CarInsurance';
import HomeInsurance from './Pages/HomeInsurance';
import FamilyInsurance from './Pages/FamilyInsurance';
import BussinessInsurance from './Pages/BussinessInsurance';
import Firebase from './Firebase';
import Context from './Context';
import "./Style.css"
import Login from './Pages/Login';
const App = () => {
  const [BlogState,SetBlogState]=useState("")
  const[Load,SetLoad]=useState(false)
  useEffect(function()
{
  SetLoad(true)
  setTimeout(function()
  {
    SetLoad(false)
  },5000)
  Firebase.child("Blogs").orderByChild("Status").equalTo("Active").on("value",function(snap)
{
  if(snap.val())
    {
      SetBlogState(snap.val())
    }
    else
    {
      SetBlogState("")
    }
})
},[])
  return (
    <div>
      {
        Load?<div className="loader-wrap">
        <div className="preloader">
            <div id="handle-preloader" className="handle-preloader">
                <div className="animation-preloader">
                <img className='myimagesss' src="assets/images/new a2z.png" alt="" />
                    <div className="spinner"></div>
                    <div className="txt-loading">
                        <span data-text-preloader="A" className="letters-loading">
                          A
                        </span>
                        <span data-text-preloader="2" className="letters-loading">
                          2
                        </span>
                        <span data-text-preloader="Z" className="letters-loading">
                            Z
                        </span>
                        <span data-text-preloader=" " className="letters-loading">
                            
                        </span>
                        <span data-text-preloader="S" className="letters-loading">
                            S
                        </span>
                        <span data-text-preloader="O" className="letters-loading">
                            O
                        </span>
                        <span data-text-preloader="L" className="letters-loading">
                            L
                        </span>
                        <span data-text-preloader="U" className="letters-loading">
                            U
                        </span>
                        <span data-text-preloader="T" className="letters-loading">
                            T
                        </span>
                        <span data-text-preloader="I" className="letters-loading">
                            I
                        </span>
                        <span data-text-preloader="O" className="letters-loading">
                            O
                        </span>
                        <span data-text-preloader="N" className="letters-loading">
                            N
                        </span>
                    </div>
                </div>   
            </div>
        </div>
    </div>:
    <div>  
      <Router>
        <Context.Provider value={{"InsertBlogs":SetBlogState,"FetchBlogs":BlogState}}>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/HomeTwo' element={<HomeTwo/>} />
          <Route path='/About' element={<About/>} />
          <Route path='/Contact' element={<Contact/>} />
          <Route path='/Testimonials' element={<Testimonials/>} />
          <Route path='/FAQ' element={<FAQ/>} />
          <Route path='/Error' element={<ErrorHandel/>} />
          <Route path='/Insurance' element={<Insurance/>} />
          <Route path='/Blog' element={<Blog/>} />
          <Route path='/BlogDetails/:key' element={<BlogDetails/>} />
          <Route path='/LifeInsurance' element={<LifeInsurance/>} />
          <Route path='/HealthInsurance' element={<HealthInsurance/>} />
          <Route path='/CarInsurance' element={<CarInsurance/>} />
          <Route path='/HomeInsurance' element={<HomeInsurance/>} />
          <Route path='/FamilyInsurance' element={<FamilyInsurance/>} />
          <Route path='/Login' element={<Login/>} />
          <Route path='/BussinessInsurance' element={<BussinessInsurance/>} />
          <Route path="*" element={<ErrorHandel/>}></Route>
        </Routes>
        </Context.Provider>
      </Router>
      </div>
            }
      </div>
  )
}

export default App