import React from 'react'
import Header from '../Components/Header'
import CommonBanner from '../Components/CommonBanner'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'

const ErrorHandel = () => {
    return (
        <div>
            {/* <Header /> */}
            {/* <CommonBanner Name={"404 Error"} /> */}
            {/*Error Page Start*/}
            <section className="error-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="error-page__inner">
                                <div className="error-page__img img-bounce">
                                    <img style={{height:"250px"}} src="assets/images/resources/error-page-img-1.png" />
                                </div>
                                <h3 className="error-page__tagline">Sorry! Page Not Found!</h3>
                                <p className="error-page__text">Oops! The page you are looking for does not exist. Please return
                                    to the site’s homepage.</p>
                                <Link to="/" className="thm-btn error-page__btn">back to home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Error Page End--*/}
            {/* <Footer /> */}
        </div>
    )
}
export default ErrorHandel