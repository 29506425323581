import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Header = (props) => {
  const [Toggle, setToggle] = useState(false)
  const [SubToggle, setSubToggle] = useState(false)
  const [SubToggletwo, setSubToggletwo] = useState(false)
  return (
    <div>
      <header className="main-header">
        <div className="main-header__top">
          <div className="main-header__top-inner">
            <ul className="list-unstyled main-header__contact-list">
              <li>
                <div className="icon">
                  <i className="icon-maps-and-flags" />
                </div>
                <div className="text">
                  <p>Chaman Medicos, Mata Darwaja Chowk,Rohtak-124001</p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <i className="icon-envelope" />
                </div>
                <div className="text">
                  <p><a href="mailto:A2ZSolutionsRTK@gmail.com">A2ZSolutionsRTK@gmail.com</a></p>
                </div>
              </li>
            </ul>
            <div className="main-header__top-social-box">
              <p className="main-header__top-social-title">Follow us:</p>
              <div className="main-header__top-social">
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="https://www.facebook.com/people/Sudhir-Bhayana/pfbid039yNcY6ZMV3Sdhna57xJvjkwhVRC7X7MNT43VU3M1eQ2JrcFYeXJ9YXz5iihJVWSl/"><i className="fab fa-facebook" /></a>
                <a href="#"><i className="fab fa-pinterest-p" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <nav className="main-menu">
          <div className="main-menu__wrapper">
            <div className="main-menu__wrapper-inner">
              <div className="main-menu__left">
                <div className="main-menu__log">
                  <Link to={'/'} ><img src="../assets/images/logo-insurify.png" style={{ height: "100px" }} /></Link>
                </div>
                <div className="main-menu__main-menu-box">
                  <a className="mobile-nav__toggler" onClick={() => setToggle(!Toggle)} ><i className="fa fa-bars" /></a>
                  <ul className="main-menu__list" style={{ paddingLeft: "140px" }}>
                    <li className={props.home ? `dropdown ${props.home}` : "dropdown"}>
                      <a style={{ fontSize: "17px", cursor: "pointer" }}>Home </a>
                      <ul>
                        <li><Link to={'/'} >Home </Link></li>
                        <li><Link to={'/HomeTwo'} >Home-2</Link></li>
                      </ul>
                    </li>
                    <li className={props.about ? props.about : ""}>
                      <Link style={{ fontStyle: "17px" }} to={'/About'}>About Us</Link>
                    </li>
                    <li className="dropdown">
                      <a style={{ fontSize: "17px", cursor: "pointer" }}>Types of Insurances</a>
                      <ul>
                        <li><Link to={'/LifeInsurance'} >Life Insurance</Link></li>
                        <li><Link to={'/HealthInsurance'} >Health Insurance</Link></li>
                        <li><Link to={'/CarInsurance'} >Car Insurance</Link></li>
                        <li><Link to={'/HomeInsurance'} >Home Insurance</Link></li>
                        <li><Link to={'/FamilyInsurance'} >Family Insurance</Link></li>
                        <li><Link to={'/BussinessInsurance'}>Business Insurance</Link></li>
                        <li><Link to={'/Insurance'} >All Insurances</Link></li>
                      </ul>
                    </li>
                    <li className={props.blog ? props.blog : ""} >
                      <Link style={{ fontStyle: "17px" }} to={'/Blog'} >Our Blogs</Link>
                    </li>
                    <li className={`${(props.test ? props.test : "" || props.faq ? props.faq : "" || props.login ? props.login : "")} "dropdown"`}>
                      <a style={{ fontSize: "17px", cursor: "pointer" }}>More Resources</a>
                      <ul>
                        <li>
                          <Link style={{ fontStyle: "17px" }} to={'/Testimonials'}>Customer's Reviews</Link>
                        </li>
                        <li>
                          <Link style={{ fontStyle: "17px" }} to={'/FAQ'}>FAQ's</Link>
                        </li>
                        <li>
                          <Link style={{ fontStyle: "17px" }} to={'/Login'}>Login</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="main-menu__right">
                {/* <div className="main-menu__search-box">
                  <form action="#" className="main-menu__search-form">
                    <input type="search" placeholder="Search...." />
                    <button type="submit"><i className="icon-search" /></button>
                  </form>
                </div> */}
                {/* <div className="main-menu__cart">
                  <a href="products.html"><span className="icon-cart" /></a>
                </div> */}
                <div className="main-menu__btn-box">
                  <Link to="/Contact" className="main-menu__btn thm-btn">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="stricky-header stricked-menu main-menu stricky-fixed">
        <div className="sticky-header__content" />{/* /.sticky-header__content */}
      </div>{/* /.stricky-header */}
      <div className={Toggle ? "mobile-nav__wrapper expanded" : "mobile-nav__wrapper"}>
        <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setToggle(!Toggle)} />
        {/* /.mobile-nav__overlay */}
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setToggle(!Toggle)} ><i className="fa fa-times" /></span>
          <div className="logo-bo">
            <Link to="/" aria-label="logo image"><img src="../assets/images/logo-insurify.png" height={110} /></Link>
          </div>
          {/* /.logo-box */}
          <div className="mobile-nav__container" />
          {/* /.mobile-nav__container */}
          <div className="mobile-nav__container"><ul className="main-menu__list">
            <li className={props.home ? `${props.home} dropdown` : "dropdown"}>
              <a className={SubToggletwo ? "expanded" : ""}>Home <button onClick={() => setSubToggletwo(!SubToggletwo)}><i className={SubToggletwo ? "fa fa-angle-left" : "fa fa-angle-down"} onClick={() => setSubToggletwo(!SubToggletwo)}></i></button></a>
              <ul style={SubToggletwo ? { display: "block" } : { display: "none" }} >
                <li><Link to="/">Home </Link></li>
                <li><Link to="/HomeTwo">Home-2</Link></li>
              </ul>
            </li>
            <li className={props.about ? props.about : ""}>
              <Link to={'/About'} >About Us</Link>
            </li>
            <li className="dropdown">
              <a className={SubToggle ? "expanded" : ""} >Types of Insurances<button onClick={() => setSubToggle(!SubToggle)} >  <i className={SubToggle ? "fa fa-angle-left" : "fa fa-angle-down"} onClick={() => setSubToggle(!SubToggle)} ></i></button></a>
              <ul style={SubToggle ? { display: "block" } : { display: "none" }} >
                <li><Link to={'/LifeInsurance'} >Life Insurance</Link></li>
                <li><Link to={'/HealthInsurance'} >Health Insurance</Link></li>
                <li><Link to={'/CarInsurance'} >Car Insurance</Link></li>
                <li><Link to={'/HomeInsurance'} >Home Insurance</Link></li>
                <li><Link to={'/FamilyInsurance'} >Family Insurance</Link></li>
                <li><Link to={'/BussinessInsurance'}>Business Insurance</Link></li>
                <li><Link to={'/Insurance'} >All Insurances</Link></li>
              </ul>
            </li>
            <li className={props.blog ? props.blog : ""}>
              <Link to={'/Blog'} >Our Blogs</Link>
            </li>
            <li className={props.contact ? props.contact : ""}>
              <Link to={'/Contact'}>Contact Us</Link>
            </li>
            <li className={props.faq ? props.faq : ""}>
              <Link to={'/FAQ'} >FAQ (Frequently Asked Questions)</Link>
            </li>
            <li className={props.test ? props.test : ""}>
              <Link to={'/Testimonials'}>Customer's Reviews</Link>
            </li>
            <li className={props.login ? props.login : ""}>
              <Link to={'/Login'}>Login</Link>
            </li>
          </ul></div>
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope" />
              <a href="mailto:A2ZSolutionsRTK@gmail.com">A2ZSolutionsRTK@gmail.com</a>
            </li>
            <li>
              <i className="fa fa-phone-alt" />
              <a href="tel:+919255433099">+91 9255433099</a>
            </li>
          </ul>{/* /.mobile-nav__contact */}
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <a href="#" className="fab fa-twitter" />
              <a href="https://www.facebook.com/people/Sudhir-Bhayana/pfbid039yNcY6ZMV3Sdhna57xJvjkwhVRC7X7MNT43VU3M1eQ2JrcFYeXJ9YXz5iihJVWSl/" className="fab fa-facebook-square" />
              <a href="#" className="fab fa-pinterest-p" />
              <a href="#" className="fab fa-instagram" />
            </div>{/* /.mobile-nav__social */}
          </div>{/* /.mobile-nav__top */}
        </div>
        {/* /.mobile-nav__content */}
      </div>
    </div>
  )
}

export default Header