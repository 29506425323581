import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../Context'

const InsuranceCategories = (props) => {
  const {FetchBlogs}=useContext(Context)
  const navigate=useNavigate()
  return (
<div className="col-xl-4 col-lg-5">
  <div className="insurance-details__right">
    <div className="insurance-details__catagories">
      <h3 className="insurance-details__catagories-title">Categories</h3>
      <ul className="insurance-details__catagories-list list-unstyled">
        <li className={props.Life?props.Life:""}>
          <Link to="/LifeInsurance">Life Insurance<span className="icon-next" /></Link>
        </li>
        <li className={props.Health?props.Health:""}>
          <Link to="/HealthInsurance">Health Insurance<span className="icon-next" /></Link>
        </li>
        <li className={props.Car?props.Car:""}>
          <Link to="/CarInsurance">Car Insurance<span className="icon-next" /></Link>
        </li>
        <li className={props.Home?props.Home:""}>
          <Link to="/HomeInsurance">Home Insurance<span className="icon-next" /></Link>
        </li>
        <li className={props.Family?props.Family:""}>
          <Link to="/FamilyInsurance">Family Insurance<span className="icon-next" /></Link>
        </li>
        <li className={props.Business?props.Business:""}>
          <Link to="/BussinessInsurance">Business Insurance<span className="icon-next" /></Link>
        </li>
      </ul>
    </div>
    <div className="insurance-details__need-help">
      <div className="insurance-details__need-help-bg" style={{backgroundImage: 'url(assets/images/backgrounds/insurance-details-need-help-bg.jpg)'}}>
      </div>
      <h3 className="insurance-details__need-help-title">Need Any Types
        <br /> of Service
        <br /> from us</h3>
      <div className="insurance-details__need-help-btn-box">
        <Link to="/Contact" className="insurance-details__need-help-btn thm-btn">FIND
          SOLUTION</Link>
      </div>
    </div>
    <div className="insurance-details__contact">
      <div className="insurance-details__contact-icon">
        <span className="icon-telephone-1" />
      </div>
      <div className="insurance-details__contact-content">
        <span>You can call anytime</span>
        <p><a href="tel:+919255433099">+91 9255433099</a></p>
      </div>
    </div>
    <br />
    <div className="sidebar__single sidebar__post">
                                    <h3 className="sidebar__title">Recently Post</h3>
                                    <ul className="sidebar__post-list list-unstyled">
                                        {
                                            FetchBlogs?Object.keys(FetchBlogs).map(function(key,index)
                                        {
                                            if(index<7)
                                            {
                                                return(
                                                    <li key={key}>
                                                    <div className="sidebar__post-image">
                                                        <img onClick={()=>navigate(`/BlogDetails/${key}`)} style={{cursor:"pointer"}} src={FetchBlogs[key]?.Heading_Image?FetchBlogs[key]?.Heading_Image:"../assets/images/blog/lp-1-1.jpg"}  />
                                                    </div>
                                                    <div className="sidebar__post-content">
                                                        <h3>
                                                            <a onClick={()=>navigate(`/BlogDetails/${key}`)} style={{cursor:"pointer"}}>{FetchBlogs[key]?.Title?FetchBlogs[key]?.Title:""}</a>
                                                            <span className="sidebar__post-content-meta"><i className="icon-clock" />{FetchBlogs[key]?.Date?FetchBlogs[key]?.Date:""}</span>
                                                        </h3>
                                                    </div>
                                                </li>
                                                )
                                            }
                                        }):""
                                        }
                                        {/* <li>
                                            <div className="sidebar__post-image">
                                                <img src="../assets/images/blog/lp-1-2.jpg"  />
                                            </div>
                                            <div className="sidebar__post-content">
                                                <h3>
                                                    <a href="blog-details.html">We’ve Been a Strategy
                                                        Thought Leader Nearly</a>
                                                    <span className="sidebar__post-content-meta"><i className="icon-clock" />April
                                                        21, 2023</span>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar__post-image">
                                                <img src="../assets/images/blog/lp-1-3.jpg"  />
                                            </div>
                                            <div className="sidebar__post-content">
                                                <h3>
                                                    <a href="blog-details.html">This Week’s Top Stories
                                                        About It</a>
                                                    <span className="sidebar__post-content-meta"><i className="icon-clock" />April
                                                        21, 2023</span>
                                                </h3>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
  </div>
</div>
  )
}

export default InsuranceCategories