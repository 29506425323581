import React from 'react'
import InsuranceCategories from './InsuranceCategories'

const CarInsuranceComponent = () => {
  return (
    <div>
      {/*Insurance Details Start*/}
<section className="insurance-details">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-7">
        <div className="insurance-details__left">
          <h3 className="insurance-details__title-1">Drive with Confidence by having Car Insurance</h3>
          <p className="insurance-details__text-1">Your car isn't just a means of transportation—it's your freedom, your independence, and a valuable asset. However, the road can be unpredictable, with risks ranging from accidents to theft and natural disasters. That's where car insurance comes in. Our comprehensive car insurance solutions are designed to protect you and your vehicle against life's uncertainties. From covering damage to your car to providing financial assistance in case of liability claims, our tailored policies ensure that you can hit the road with confidence. Invest in the security and well-being of your vehicle today with our trusted insurance coverage.
          </p>
          <div className="insurance-details__img-1">
            <img src="assets/images/services/insurance-details-img-2.jpg" alt />
          </div>
          <h3 className="insurance-details__title-2">Invest in Your Car's Security: Trusted Insurance Policies</h3>
          <p className="insurance-details__text-2">Car insurance offers essential protection for you and your vehicle on the road. Our comprehensive policies are tailored to safeguard against unforeseen events, such as accidents, theft, or damage. With reliable coverage and peace of mind, you can drive with confidence, knowing that you're financially protected. Protect your ride and invest in your peace of mind with our trusted car insurance solutions.</p>
          <div className="insurance-details__points-and-text-box">
            <div className="insurance-details__points-box">
              <h3 className="insurance-details__points-title">Our Goals</h3>
              <ul className="insurance-details__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Vehicle Repair or Replacement</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Personal Injury Protection</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Uninsured/Underinsured Motorist Coverage</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Legal Compliance</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Promoting Safe Driving</p>
                </li>
              </ul>
            </div>
            <div className="insurance-details__text-box">
              <h3>The Challenges</h3>
              <p>Car insurance premiums can be expensive, <br /> especially for drivers with poor driving records, <br /> high-risk vehicles, or limited coverage options.<br /> Affordability is a significant challenge for many <br /> drivers and
              understanding the various coverage <br /> options, deductibles, limits, and exclusions in <br /> car insurance policies can be challenging for <br /> drivers, leading to confusion and potentially inadequate coverage.</p>
            </div>
          </div>
          <div className="insurance-details__faq">
            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What is car insurance? Why do I need car insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Car insurance is a type of insurance policy that provides financial protection for drivers in the event of accidents, theft, vandalism, or other covered incidents involving their vehicles. Car insurance is essential for protecting yourself, your passengers, and your vehicle from financial losses resulting from accidents, theft, or other covered incidents. It also helps cover liability for injuries or property damage caused to others.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion  active">
                <div className="accrodion-title">
                  <h4>What does car insurance cover?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Car insurance typically covers damage to your vehicle, liability for injuries or property damage to others, medical expenses for you and your passengers, and other related costs depending on the specific policy and coverage options.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>Is car insurance mandatory? Can I save money on car insurance premiums?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Yes, car insurance is mandatory in most states, which require drivers to carry a minimum level of liability insurance coverage to legally operate a vehicle on public roads. Failure to comply with state insurance requirements can result in fines, license suspension, or other penalties. and Yes, there are several ways to potentially save money on car insurance premiums, including bundling policies, maintaining a good driving record, choosing a higher deductible, qualifying for discounts, and periodically reviewing your coverage needs.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What if I'm involved in an accident with an uninsured driver?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>If you're involved in an accident with an uninsured driver, uninsured/underinsured motorist coverage on your policy can help cover your medical expenses, vehicle repairs, and other losses, depending on your coverage limits.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   <InsuranceCategories Car="active"/>
    </div>
  </div>
</section>
{/*Insurance Details End*/}

    </div>
  ) 
}

export default CarInsuranceComponent