import React from 'react'
import InsuranceCategories from './InsuranceCategories'

const FamilyInsuranceComponent = () => {
  return (
    <div>
   {/*Insurance Details Start*/}
<section className="insurance-details">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-7">
        <div className="insurance-details__left">
          <h3 className="insurance-details__title-1">Care for Your Loved Ones: Family-Focused Insurance Solutions</h3>
          <p className="insurance-details__text-1">In a world full of uncertainties, ensuring the well-being and security of your family is paramount. Family insurance provides a safety net, offering financial protection and peace of mind for you and your loved ones in the face of unexpected events. From safeguarding your home and belongings to providing for your family's health and future, our comprehensive family insurance solutions are tailored to meet your unique needs. With a range of options designed to cover everything from medical expenses to property damage, we're here to help you protect what matters most – your family.
          </p>
          <div className="insurance-details__img-1">
            <img src="assets/images/services/insurance-details-img-3.jpg" alt />
          </div>
          <h3 className="insurance-details__title-2">Family Shield: Reliable Insurance for Your Household</h3>
          <p className="insurance-details__text-2">Family insurance provides essential protection for your loved ones, offering financial security in times of need. From health coverage to safeguarding your home and belongings, our comprehensive insurance plans are tailored to meet the diverse needs of your family. With reliable coverage and peace of mind, you can rest assured knowing that your family is protected against life's uncertainties.</p>
          <div className="insurance-details__points-and-text-box">
            <div className="insurance-details__points-box">
              <h3 className="insurance-details__points-title">Our Goals</h3>
              <ul className="insurance-details__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Healthcare Coverage</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Home and Property Protection</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Emergency Fund</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Income Replacement</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Legal Protection</p>
                </li>
              </ul>
            </div>
            <div className="insurance-details__text-box">
              <h3>The Challenges</h3>
              <p>Balancing the cost of insurance premiums with <br /> the need for adequate coverage can be challenging <br /> for families, especially those on a tight budget and <br /> Understanding the terms, conditions, and coverage <br /> limitations of insurance policies can be overwhelming <br /> for families, leading to potential misunderstandings <br /> or inadequate coverage.</p>
            </div>
          </div>
          <div className="insurance-details__faq">
            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What is family insurance? and What types of insurance are included in family insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Family insurance refers to a range of insurance policies designed to protect families from financial risks and uncertainties, including health, life, property, and liability coverage. Family insurance typically includes health insurance, life insurance, homeowners or renters insurance, auto insurance, and umbrella liability insurance, among others, to provide comprehensive coverage for various aspects of family life.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion  active">
                <div className="accrodion-title">
                  <h4>Why do I need family insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Family insurance provides financial protection and peace of mind for you and your loved ones, ensuring that you are prepared for unexpected events such as illness, injury, death, property damage, or liability claims.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>How do I choose the right family insurance policies?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Assess your family's specific needs, budget, and risk factors, and consider factors such as coverage options, deductibles, premiums, policy limits, and exclusions. Consult with insurance professionals to help you select the most suitable policies for your family.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>Can I bundle my family insurance policies for savings?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Yes, many insurance companies offer discounts for bundling multiple policies, such as combining home and auto insurance or adding additional coverage like life insurance to your existing policies.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InsuranceCategories Family="active"/>
    </div>
  </div>
</section>
{/*Insurance Details End*/}
    </div>
  )
}
export default FamilyInsuranceComponent