import React from 'react'
import Header from '../Components/Header'
import CommonBanner from '../Components/CommonBanner'
import CarInsuranceComponent from '../Components/CarInsuranceComponent'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'
const CarInsurance = () => {
  return (
    <div>  
        <Header/>
        <CommonBanner Name={"Car Insurance"} />
        <CarInsuranceComponent/>
        <Footer/>
        <ScrollToTop/>
    </div>
  )
}
export default CarInsurance