import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Firebase from "../Firebase"
const LoginComponent = () => {
    const[Obj,SetObj]=useState({})
    const[Error,SetError]=useState(true)
    const navigate=useNavigate()
    function set(event)
    {
        SetObj({...Obj,[event.target.name]:event.target.value})
    }
    function login(e)
    {
        e.preventDefault()
        if(Obj.Username && Obj.Password)
        {
        Firebase.child("LoginDetails").on("value",function(snap)
        {
            if(snap.val())
            {
                if(snap.val().Username==Obj.Username && snap.val().Password==Obj.Password)
                {
                    toast.success("Login Successful")
                    SetError(true)
                    setTimeout(() => {
                        navigate("//admin.a2zinsurance.online")
                    }, 1500);
                }
                else
                {
                    toast.error("Wrong Username and Password")
                    SetError(false)
                }
            }
            else
            {
                toast.error("Wrong Username and Password")
                SetError(false)
            }
        })
        }
        else
        {
            toast.warning("Field is Empty")
            SetError(true)
        }
    }
  return (
    <div>
      <section className="contact-page">
        <div className="container">
        <ToastContainer position='top-center'/>
            <div className="row">
                <div className="col-xl-7 col-lg-7">
                    <div style={{position:"relative",bottom:"90px"}} className="contact-page__left">
                    <div className="contact-page__bottom">
                    <div className="comment-form">
                    <h3 className="comment-one__title text-center">My Account</h3>
                    <p>Login via Username*</p>
                    <form className="comment-one__form contact-form-validated" noValidate="novalidate">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="comment-form__input-box">
                                    <input type="text" onChange={set}  placeholder="Enter your Username*" name="Username" />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="comment-form__input-box">
                                    <input type="password" style={{backgroundColor:"white"}} onChange={set} placeholder="Enter your Password*" name="Password" />
                                    {!Error && <p style={{ color: 'red' }}>Please enter a valid Username and Password</p>}
                                </div>
                            </div>
                            <div className="comment-form__btn-box text-center">
                                <button type="submit" onClick={login} className="thm-btn comment-form__btn">Sign in</button>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5">
                    <div className="contact-page__right">
                        <div className="contact-page__img">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d111770.317035707!2d76.572746!3d28.903625000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d85955835d6d7%3A0x9beb3ac259119d65!2sChaman%20Medicose!5e0!3m2!1sen!2sin!4v1716663601724!5m2!1sen!2sin" width="550" height="470" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
export default LoginComponent