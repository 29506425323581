import React from 'react'
import InsuranceCategories from './InsuranceCategories'

const LifeInsuranceComponent = () => {
  return (
    <div>
{/*Insurance Details Start*/}
<section className="insurance-details">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-7">
        <div className="insurance-details__left">
          <h3 className="insurance-details__title-1">Peace of Mind for You and Your Loved Ones: Life Insurance Options </h3>
          <p className="insurance-details__text-1">Life Insurance ensures the financial security of your loved ones is more important than ever. Life insurance offers a safety net, providing peace of mind that your family will be taken care of no matter what the future holds. Whether you’re looking to protect your family’s financial stability, cover outstanding debts, or leave a lasting legacy, our comprehensive life insurance plans are designed to meet your unique needs. With a range of options tailored to different life stages and budgets, securing your family's future has never been easier. Invest in peace of mind today and let us help you safeguard what matters most.
          </p>
          <div className="insurance-details__img-1">
            <img src="assets/images/services/insurance-details-img-4.jpg" alt />
          </div>
          <h3 className="insurance-details__title-2">Safeguard Your Loved Ones with Our Life Insurance Plans</h3>
          <p className="insurance-details__text-2">Life insurance provides essential financial protection for your loved ones, ensuring their stability and peace of mind in the event of the unexpected. Our diverse range of life insurance policies is designed to fit different needs and budgets, offering coverage options that can help secure your family's future, pay off debts, or create a lasting legacy. Protect what matters most with our reliable and affordable life insurance solutions.</p>
          <div className="insurance-details__points-and-text-box">
            <div className="insurance-details__points-box">
              <h3 className="insurance-details__points-title">Our Goals</h3>
              <ul className="insurance-details__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Financial Security for Loved Ones</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Educational Expenses</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Legacy Creation</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Peace of Mind</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Debt Coverage</p>
                </li>
              </ul>
            </div>
            <div className="insurance-details__text-box">
              <h3>The Challenges</h3>
              <p>The challenges faced by the customer is to choose <br /> with various types of life insurance policies available <br /> (term, whole, universal, etc.), selecting the right one <br /> that fits your specific needs and financial <br /> situation can be overwhelming.</p>
            </div>
          </div>
          <div className="insurance-details__faq">
            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What is life insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Life insurance is a contract between an individual and an insurance company where the insurer provides a lump-sum payment to beneficiaries upon the insured's death in exchange for premium payments.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion  active">
                <div className="accrodion-title">
                  <h4>How does life insurance work?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>When you purchase a life insurance policy, you agree to pay regular premiums. In return, the insurance company promises to pay a death benefit to your designated beneficiaries upon your passing.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What types of life insurance are available?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>The main types are term life insurance, whole life insurance, and universal life insurance. Term life provides coverage for a specific period, whole life offers lifelong coverage with a cash value component, and universal life includes flexible premiums and death benefits.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>How much life insurance do I need?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>The amount of coverage you need depends on factors such as your income, debts, lifestyle, future financial goals, and the needs of your dependents. A common rule of thumb is to have coverage that’s 5-10 times your annual income.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InsuranceCategories Life="active"/>
    </div>
  </div></section>
{/*Insurance Details End*/}

    </div>
  )
}

export default LifeInsuranceComponent