import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css'; // Import Swiper's core styles
import 'swiper/css/navigation'; // Import Swiper's navigation styles
import 'swiper/css/pagination'; // Import Swiper's pagination styles
import { Autoplay, Parallax, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const HomeClientReview = () => {
  return (
    <div>
        {/*Testimonial One Start */}
    <section className="testimonial-one testimonial-two">
      <div className="container mybox-3">
        <div className="section-title text-center sec-title-animation animation-style1">
          <div className="section-title__tagline-box">
            <p className="section-title__tagline">OUR CLIENT AWESOME REVIEWS</p>
          </div>
          <h2 className="section-title__title title-animation">Clients Feedback Already
            <br /> They Gived Us</h2>
        </div>
        <div className="testimonial-one__bottom">
        <Swiper breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10
          }
        }} spaceBetween={10}
                slidesPerView={4}
                modules={[Autoplay, Parallax, Pagination, Navigation]}
                // autoplay={{ delay: 3000 }}
                // speed={2000}
                parallax={true} navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }} className="swiper-container-h" >
          <div className="testimonial-one__carousel owl-carousel owl-theme thm-owl__carousel">
            {/*Testimonial One Single Start*/}
            <SwiperSlide>  <div className="item">
              <div className="testimonial-one__single">
                <div className="testimonial-one__quote">
                  <img src="assets/images/icon/quote-icon-2.png"  />
                </div>
                <div className="testimonial-one__text-box">
                  <p className="testimonial-one__text">I recieved help from Star during my surgery and revovery. And my faith in the company has increased a lot. Thanks a lot to them and their team.</p>
                </div>
                <div className="testimonial-one__client-info">
                  <div className="testimonial-one__client-img">
                    <img src="assets/images/textimonial.png"  />
                  </div>
                  <h3 className="testimonial-one__client-name"><Link to="/Testimonials">Damayanti Upandhye</Link>
                  </h3>
                  <p className="testimonial-one__client-sub-title">Entrepreneur</p>
                </div>
              </div>
            </div></SwiperSlide>
            {/*Testimonial One Single Start*/}
            {/*Testimonial One Single Start*/}
            <SwiperSlide>
            <div className="item">
              <div className="testimonial-one__single">
                <div className="testimonial-one__quote">
                  <img src="assets/images/icon/quote-icon-2.png"  />
                </div>
                <div className="testimonial-one__text-box">
                  <p className="testimonial-one__text">It provides you with financial protection in the event of unexpected death, illness and injury. It also protect us and our families with compassion of security of shield.</p>
                </div>
                <div className="testimonial-one__client-info">
                  <div className="testimonial-one__client-img">
                    <img src="assets/images/textimonial2.png"  />
                  </div>
                  <h3 className="testimonial-one__client-name"><Link to="/Testimonials">Gaurav Bhatia</Link>
                  </h3>
                  <p className="testimonial-one__client-sub-title">Manager</p>
                </div>
              </div>
            </div>
            </SwiperSlide>
            {/*Testimonial One Single Start*/}
            {/*Testimonial One Single Start*/}
            <SwiperSlide>
            <div className="item">
              <div className="testimonial-one__single">
                <div className="testimonial-one__quote">
                  <img src="assets/images/icon/quote-icon-2.png"  />
                </div>
                <div className="testimonial-one__text-box">
                  <p className="testimonial-one__text">I&#039;ve recommended this insurance company to all my friends & family because they provide such great coverage at an affordable price.</p>
                </div>
                <div className="testimonial-one__client-info">
                  <div className="testimonial-one__client-img">
                    <img src="assets/images/testimonial-83S5W35-150x150.PNG"  />
                  </div>
                  <h3 className="testimonial-one__client-name"><Link to="/Testimonials">Sarabjit Kaur</Link></h3>
                  <p className="testimonial-one__client-sub-title">Housewife</p>
                </div>
              </div>
            </div>
            </SwiperSlide>
            {/*Testimonial One Single Start*/}
            {/*Testimonial One Single Start*/}
            <SwiperSlide>
            <div className="item">
              <div className="testimonial-one__single">
                <div className="testimonial-one__quote">
                  <img src="assets/images/icon/quote-icon-2.png"  />
                </div>
                <div className="testimonial-one__text-box">
                  <p className="testimonial-one__text">This insurance company truly understands the value of customer service. They always put me first and have made me a customer for life.</p>
                </div>
                <div className="testimonial-one__client-info">
                  <div className="testimonial-one__client-img">
                    <img src="assets/images/testimonial-LDXAEEM-150x150.PNG"  />
                  </div>
                  <h3 className="testimonial-one__client-name"><Link to="/Testimonial">Sanjay Tandon</Link>
                  </h3>
                  <p className="testimonial-one__client-sub-title">Marketing -Zintra</p>
                </div>
              </div>
            </div>
            </SwiperSlide>
            {/*Testimonial One Single Start*/}
            {/*Testimonial One Single Start*/}
            <SwiperSlide>
            <div className="item">
              <div className="testimonial-one__single">
                <div className="testimonial-one__quote">
                  <img src="assets/images/icon/quote-icon-2.png"  />
                </div>
                <div className="testimonial-one__text-box">
                  <p className="testimonial-one__text">Thanks to this insurance company, I&#039;m able to sleep soundly at night knowing that I&#039;m protected. Their policies are comprehensive.</p>
                </div>
                <div className="testimonial-one__client-info">
                  <div className="testimonial-one__client-img">
                    <img src="assets/images/testtimonial1.PNG"  />
                  </div>
                  <h3 className="testimonial-one__client-name"><Link to="/Testimonial">Abhi Patvardhan</Link>
                  </h3>
                  <p className="testimonial-one__client-sub-title">Businessman</p>
                </div>
              </div>
            </div>
            </SwiperSlide>
            {/*Testimonial One Single Start*/}
            {/*Testimonial One Single Start*/}
            <SwiperSlide>
            <div className="item">
              <div className="testimonial-one__single">
                <div className="testimonial-one__quote">
                  <img src="assets/images/icon/quote-icon-2.png"  />
                </div>
                <div className="testimonial-one__text-box">
                  <p className="testimonial-one__text">I recently had to file a claim with my insurance company and was blown away by how the process was so efficient and easy.</p>
                </div>
                <div className="testimonial-one__client-info">
                  <div className="testimonial-one__client-img">
                    <img src="assets/images/portrait-of-successful-senior-businessman-gray-PRHRJ6H-150x150.PNG"  />
                  </div>
                  <h3 className="testimonial-one__client-name"><Link to="/Testimonial">Govind Sharma</Link></h3>
                  <p className="testimonial-one__client-sub-title">Google</p>
                </div>
              </div>
            </div>
            </SwiperSlide>
            {/*Testimonial One Single Start*/}
          </div>
          </Swiper>
        </div>
      </div>
    </section>
    {/*Testimonial One End */}
    </div>
  )
}

export default HomeClientReview