import React from 'react'
import { Link } from 'react-router-dom'

const HomeFeatures = () => {
  return (
    <div className='container'>
            {/*Feature One Start */}
    <section className="feature-one">
      <div className="container">
        <div className="row">
          {/*Feature One Single Start*/}
          <div className="col-xl-4 col-lg-6 wow fadeInLeft" data-wow-delay="100ms">
            <div className="feature-one__single">
              <div className="feature-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/feature-one-shape-1.png)'}} />
              <div className="feature-one__hover-shape-1" style={{backgroundImage: 'url(assets/images/shapes/feature-one-hover-shape-1.png)'}}>
              </div>
              <div className="feature-one__icon">
                <span className="icon-protection" />
              </div>
              <div className="feature-one__content">
                <p className="feature-one__sub-title">Insurance Agency</p>
                <h3 className="feature-one__title"><Link to="/LifeInsurance">First &amp; Reliable</Link></h3>
                <Link to="/LifeInsurance" className="feature-one__read-more">READ MORE<span className="icon-next" /></Link>
              </div>
            </div>
          </div>
          {/*Feature One Single End*/}
          {/*Feature One Single Start*/}
          <div className="col-xl-4 col-lg-6 wow fadeInUp" data-wow-delay="300ms">
            <div className="feature-one__single">
              <div className="feature-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/feature-one-shape-1.png)'}} />
              <div className="feature-one__hover-shape-1" style={{backgroundImage: 'url(assets/images/shapes/feature-one-hover-shape-1.png)'}}>
              </div>
              <div className="feature-one__icon">
                <span className="icon-investment" />
              </div>
              <div className="feature-one__content">
                <p className="feature-one__sub-title">Insurance Agency</p>
                <h3 className="feature-one__title"><Link to="/BussinessInsurance">Invest Your Money</Link></h3>
                <Link to="/BussinessInsurance" className="feature-one__read-more">READ MORE<span className="icon-next" /></Link>
              </div>
            </div>
          </div>
          {/*Feature One Single End*/}
          {/*Feature One Single Start*/}
          <div className="col-xl-4 col-lg-6 wow fadeInRight" data-wow-delay="100ms">
            <div className="feature-one__single">
              <div className="feature-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/feature-one-shape-1.png)'}} />
              <div className="feature-one__hover-shape-1" style={{backgroundImage: 'url(assets/images/shapes/feature-one-hover-shape-1.png)'}}>
              </div>
              <div className="feature-one__icon">
                <span className="icon-risk-management" />
              </div>
              <div className="feature-one__content">
                <p className="feature-one__sub-title">Insurance Agency</p>
                <h3 className="feature-one__title"><Link to="/FamilyInsurance">Get Your Insurance</Link></h3>
                <Link to="/FamilyInsurance" className="feature-one__read-more">READ MORE<span className="icon-next" /></Link>
              </div>
            </div>
          </div>
          {/*Feature One Single End*/}
        </div>
      </div>
    </section>
    {/*Feature One End */}
    </div>
  )
}

export default HomeFeatures