import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Firebase from "../Firebase"
import emailjs from "@emailjs/browser"
import {useNavigate} from "react-router-dom"
const HomeQuote = () => {
  const[Obj,SetObj]=useState({
    Name:"",
    Phone:"",
    Email:"",
    Message:""
})
const[Error,SetError]=useState(true)
const navigate=useNavigate()
const d=new Date()
const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
useEffect(function()
{
    emailjs.init("ehUfdXxMi8tVINNKj");
},[])
function set(event)
{   
    SetObj({...Obj,[event.target.name]:event.target.value.toLowerCase(),"Date":date})
}
function EmailChange(email){
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email)
  }
  function NameChange(event){
    const name = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    SetObj({...Obj,"Name":name.toLowerCase()});
  }
  function PhoneNumberChange(event)
  {
    const phone = event.target.value.trim().replace(/[^\d-]/g, '');
    SetObj({...Obj,"Phone":phone.toLowerCase()})
  }
function Submit(e)
{
    e.preventDefault()
    if(Obj.Name!="" && Obj.Phone!="" && Obj.Email!="" && Obj.Message!="")
        {
           const response= EmailChange(Obj.Email)
           if(response)
           {
            SetError(true)
            Firebase.child("Contacts").push(Obj,async(err)=>{
            if(!err)
            {
                toast.success("Thank you for contacting us. We will contact you soon.")
                await EmailSend(Obj)
                setTimeout(() => {
                    navigate("/")
                }, 5000);
            }
            else
            {
                toast.error("Error Occured.Try again later")
            }
            })
           }
           else
           {
            SetError(false)
            toast.error("Please enter a valid email address")
           }
        }
        else
        {
            toast.warning("Field is Empty")
        }
}
async function EmailSend(Obj)
{
    const ServiceID="service_h4xhf3p"
const TemplateID="template_w19t30k"
  await emailjs.send(ServiceID,TemplateID,Obj).then((resp)=>{
console.log("Email Send")
}).catch((err)=>{
console.log(err);
})
}
  return (
    <div>
      <ToastContainer position='top-center'/>
        {/*Get Quote Start */}
    <section className="get-quote get-quote-three">
      <div className="get-quote-three__shape-1" style={{backgroundImage: 'url(assets/images/shapes/get-quote-three-shape-1.png)'}} />
      <div className="get-quote-three__shape-2">
        <img src="assets/images/shapes/get-quote-three-shape-2.png"  />
      </div>
      <div className="container">
        <div className="get-quote__inner">
          <div className="row tabs-box">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="get-quote__left clearfix">
                <div className="get-quote-three__img-1">
                  <img src="assets/images/resources/get-quote-three-img-1.png"  />
                </div>
                <h3 className="get-quote-three__title">Get an Insurance
                  <br /> Related Information.</h3>
                <div className="get-quote__tab-box clearfix">
                  <ul className="tab-buttons clearfix list-unstyled">
                    <li data-tab="#life" className="tab-btn">
                      <div className="get-quote__tab-btn-content">
                        <div className="get-quote__tab-icon">
                          <span className="icon-life-insurance-1" />
                        </div>
                        <div className="get-quote__tab-text-box">
                          <p className="get-quote__tab-text">Life</p>
                        </div>
                      </div>
                    </li>
                    <li data-tab="#veichle" className="tab-btn">
                      <div className="get-quote__tab-btn-content">
                        <div className="get-quote__tab-icon">
                          <span className="icon-insurance-1" />
                        </div>
                        <div className="get-quote__tab-text-box">
                          <p className="get-quote__tab-text">Vehicle</p>
                        </div>
                      </div>
                    </li>
                    <li data-tab="#home" className="tab-btn">
                      <div className="get-quote__tab-btn-content">
                        <div className="get-quote__tab-icon">
                          <span className="icon-home-insurance" />
                        </div>
                        <div className="get-quote__tab-text-box">
                          <p className="get-quote__tab-text">Home</p>
                        </div>
                      </div>
                    </li>
                    <li data-tab="#business" className="tab-btn">
                      <div className="get-quote__tab-btn-content">
                        <div className="get-quote__tab-icon">
                          <span className="icon-briefcase" />
                        </div>
                        <div className="get-quote__tab-text-box">
                          <p className="get-quote__tab-text">Business</p>
                        </div>
                      </div>
                    </li>
                    <li data-tab="#health" className="tab-btn">
                      <div className="get-quote__tab-btn-content">
                        <div className="get-quote__tab-icon">
                          <span className="icon-health-insurance" />
                        </div>
                        <div className="get-quote__tab-text-box">
                          <p className="get-quote__tab-text">Health</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="get-quote__right">
                <div className="tabs-content">
                  {/*tab*/}
                  <div className="tab active-tab" id="life">
                    <div className="get-quote__content">
                      <p className="get-quote__tagline">Let's Get Started</p>
                      <h2 className="get-quote__title">Enter the Form</h2>
                      <form className="get-quote__form">
                        <div className="get-quote__content-box">
                          <div className="get-quote__input-box">
                            <input type="text" placeholder="Your Name" value={Obj.Name} onChange={NameChange} name="Name" />
                          </div>
                          <div className="get-quote__input-box">
                            <input type="email" placeholder="Email Address" onChange={set} name="Email" />
                            {!Error && <p style={{ color: 'red' }}>Please enter a valid email address</p>}
                          </div>
                          <div className="get-quote__input-box">
                            <input type="tel" placeholder="Phone Number" value={Obj.Phone} maxLength={10} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={PhoneNumberChange} name="Phone" />
                          </div>
                          <div className="get-quote__input-box">
                            <input type="text" placeholder="Any Message" onChange={set} name="Message" />
                          </div>
                        </div>
                        <div className="get-quote__content-bottom">
                          <button type="submit" className="thm-btn get-quote__btn" onClick={Submit}>Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/*tab*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*Get Quote End */}
    <br />
    <br />
    <br />
    <br />
    </div>
  )
}
export default HomeQuote