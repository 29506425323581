import { Line } from 'rc-progress'
import React from 'react'

const HomeAbout = () => {
  return (
    <div>
        {/*About Three Start */}
    <section className="about-three">
      <div className="container">
        <div className="row">
          <div className="col-xl-5">
            <div className="about-three__left">
              <div className="about-three__img-box  wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                <div className="about-three__img">
                  <img src="assets/images/resources/about-three-img-1.jpg"  />
                </div>
                <div className="about-three__img-2">
                  <img src="assets/images/resources/about-three-img-2.jpg"  />
                </div>
                <div className="about-three__img-3">
                  <img src="assets/images/resources/about-three-img-3.jpg"  />
                </div>
                <div className="about-three__shape-1">
                  <img src="assets/images/shapes/about-three-shape-1.png"  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <div className="about-three__right">
              <div className="section-title text-left sec-title-animation animation-style1">
                <div className="section-title__tagline-box">
                  <p className="section-title__tagline">KNOW ABOUT A2Z Solutions</p>
                </div>
                <h2 className="section-title__title title-animation">Get Insurance Solution
                  <br /> With Us.</h2>
              </div>
              <p className="about-three__text">Our insurance company is dedicated to providing comprehensive and reliable coverage for individuals and businesses alike. We are experienced advisor writing quality insurance coverage since 2000 with having many satisfied clients.</p>
              <div className="about-three__award-box">
                <div className="icon">
                  <span className="icon-trophy" />
                </div>
                <div className="content">
                  <h3>Our Mission & Vision</h3>
                  <p>Our mission is to provide our insurers with superb coverage and claims handling through careful of risks & business-friendly solutions. We want to be the insurance agency of choice, dedicated to educating, serving, protecting families and businesses with the best insurance policies.</p>
                </div>
              </div>
              <div className="about-three__progress">
                <h4 className="about-three__progress-title">Satisfied Clients</h4>
                <Line percent={95} strokeWidth={1.75} strokeColor="green" trailWidth={15}/>
              </div>
              {/* <div className="about-three__btn-and-client">
                <div className="about-three__client-img">
                  <img src="assets/images/resources/about-three-client-img.jpg"  />
                  <div className="about-three__client-signature">
                    <img src="assets/images/shapes/about-three-client-signature.png"  />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*About Three End */}
    </div>
  )
}

export default HomeAbout