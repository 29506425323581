import React, { useContext } from 'react'
import Context from "../Context"
import { useNavigate } from 'react-router-dom'
const BlogComponent = () => {
  const {FetchBlogs}=useContext(Context)
 const navigate= useNavigate()
  return (
    <div>{/*Blog Page Start*/}
<section className="blog-page">
  <div className="container">
    <div className="row">
      {/*BLog One Single Start*/}
     {
      FetchBlogs?Object.keys(FetchBlogs).reverse().map(function(key)
    {
      return(
        <div style={{cursor:"pointer"}} key={key} className="col-xl-4 col-lg-4">
        <div className="blog-one__single">
          <div className="blog-one__img-box">
            <div className="blog-one__img" onClick={()=>(navigate(`/BlogDetails/${key}`))}>
              <img style={{height:"180px"}} src={FetchBlogs[key].Heading_Image?FetchBlogs[key].Heading_Image:"assets/images/blog/blog-1-1.jpg"}  />
            </div>
          </div>
          <div className="blog-one__content">
            <div className="blog-one__tag">
              {
                FetchBlogs[key].Tags?FetchBlogs[key].Tags.split(",").map(function(tag)
              {
                return(
                  <span key={tag}>{tag}</span>
                )
              }):""
              }
            </div>
            <h3 className="blog-one__title">
              <a onClick={()=>(navigate(`/BlogDetails/${key}`))}>{FetchBlogs[key].Heading?FetchBlogs[key].Heading:"Heading"}</a></h3>
            <div className="blog-one__client-info">
              <div className="blog-one__client-img">
                <img style={{height:"60px",width:"60px"}} src={FetchBlogs[key].Author_Image?FetchBlogs[key].Author_Image:"assets/images/blog/blog-one__client-img-1.jpg"} />
              </div>
              <div className="blog-one__client-content">
                <h3>{FetchBlogs[key].Author?FetchBlogs[key].Author:"Sudhir Bhayana"}</h3>
                <p>{FetchBlogs[key].Date?FetchBlogs[key].Date:"Date"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    }):""
     }
      {/*BLog One Single End*/}
      {/*BLog One Single Start*/}
      {/* <div className="col-xl-4 col-lg-4">
        <div className="blog-one__single">
          <div className="blog-one__img-box">
            <div className="blog-one__img">
              <img src="assets/images/blog/blog-1-2.jpg" alt />
            </div>
          </div>
          <div className="blog-one__content">
            <div className="blog-one__tag">
              <span>Insurance</span>
              <span>Agency</span>
            </div>
            <h3 className="blog-one__title"><a href="blog-details.html">Insurance Horizons
                Exploring the Offerings</a></h3>
            <div className="blog-one__client-info">
              <div className="blog-one__client-img">
                <img src="assets/images/blog/blog-one__client-img-2.jpg" alt />
              </div>
              <div className="blog-one__client-content">
                <h3>Rosig Kerpt</h3>
                <p>August 3, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*BLog One Single End*/}
      {/*BLog One Single Start*/}
      {/* <div className="col-xl-4 col-lg-4">
        <div className="blog-one__single">
          <div className="blog-one__img-box">
            <div className="blog-one__img">
              <img src="assets/images/blog/blog-1-3.jpg" alt />
            </div>
          </div>
          <div className="blog-one__content">
            <div className="blog-one__tag">
              <span>Insurance</span>
              <span>Agency</span>
            </div>
            <h3 className="blog-one__title"><a href="blog-details.html">Safeguarding Futures
                The Passion Insurance</a></h3>
            <div className="blog-one__client-info">
              <div className="blog-one__client-img">
                <img src="assets/images/blog/blog-one__client-img-3.jpg" alt />
              </div>
              <div className="blog-one__client-content">
                <h3>Tamu Jana</h3>
                <p>August 3, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*BLog One Single End*/}
      {/*BLog One Single Start*/}
      {/* <div className="col-xl-4 col-lg-4">
        <div className="blog-one__single">
          <div className="blog-one__img-box">
            <div className="blog-one__img">
              <img src="assets/images/blog/blog-1-4.jpg" alt />
            </div>
          </div>
          <div className="blog-one__content">
            <div className="blog-one__tag">
              <span>Insurance</span>
              <span>Agency</span>
            </div>
            <h3 className="blog-one__title"><a href="blog-details.html">Commodo venenatis ligula commodo
                leez</a></h3>
            <div className="blog-one__client-info">
              <div className="blog-one__client-img">
                <img src="assets/images/blog/blog-one__client-img-4.jpg" alt />
              </div>
              <div className="blog-one__client-content">
                <h3>Robin Sons</h3>
                <p>August 3, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*BLog One Single End*/}
      {/*BLog One Single Start*/}
      {/* <div className="col-xl-4 col-lg-4">
        <div className="blog-one__single">
          <div className="blog-one__img-box">
            <div className="blog-one__img">
              <img src="assets/images/blog/blog-1-5.jpg" alt />
            </div>
          </div>
          <div className="blog-one__content">
            <div className="blog-one__tag">
              <span>Insurance</span>
              <span>Agency</span>
            </div>
            <h3 className="blog-one__title"><a href="blog-details.html">Etiam aliquam massa quis des
                mauris</a></h3>
            <div className="blog-one__client-info">
              <div className="blog-one__client-img">
                <img src="assets/images/blog/blog-one__client-img-5.jpg" alt />
              </div>
              <div className="blog-one__client-content">
                <h3>Jara Akser</h3>
                <p>August 3, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*BLog One Single End*/}
      {/*BLog One Single Start*/}
      {/* <div className="col-xl-4 col-lg-4">
        <div className="blog-one__single">
          <div className="blog-one__img-box">
            <div className="blog-one__img">
              <img src="assets/images/blog/blog-1-6.jpg" alt />
            </div>
          </div>
          <div className="blog-one__content">
            <div className="blog-one__tag">
              <span>Insurance</span>
              <span>Agency</span>
            </div>
            <h3 className="blog-one__title"><a href="blog-details.html">Aliquam hendrerit a augue
                insuscipit.</a></h3>
            <div className="blog-one__client-info">
              <div className="blog-one__client-img">
                <img src="assets/images/blog/blog-one__client-img-6.jpg" alt />
              </div>
              <div className="blog-one__client-content">
                <h3>Alisha Martin</h3>
                <p>August 3, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*BLog One Single End*/}
    </div>
  </div>
</section>
{/*Blog Page End*/}
</div>
  )
}

export default BlogComponent