import React from 'react'
import InsuranceCategories from './InsuranceCategories'

const BussinessInsuranceComponent = () => {
  return (
    <div>
      {/*Insurance Details Start*/}
<section className="insurance-details">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-7">
        <div className="insurance-details__left">
          <h3 className="insurance-details__title-1">Safeguard Your Enterprise: Essential Business Insurance</h3>
          <p className="insurance-details__text-1">In today's dynamic business environment, safeguarding your enterprise against unexpected risks is crucial for long-term success. Business insurance provides essential protection, covering a wide range of potential threats from property damage and liability claims to employee injuries and business interruptions. Whether you're a small startup or an established corporation, our tailored business insurance solutions are designed to meet your unique needs, ensuring your operations remain secure and resilient. Invest in peace of mind with comprehensive coverage that protects what you've worked so hard to build.
          </p>
          <div className="insurance-details__img-1">
            <img src="assets/images/services/insurance-details-img-1.jpg" alt />
          </div>
          <h3 className="insurance-details__title-2">Insure Your Success: Tailored Business Insurance Plans</h3>
          <p className="insurance-details__text-2">Business insurance is essential for protecting your company from a variety of risks, including property damage, liability claims, employee injuries, and business interruptions. Our comprehensive and tailored insurance solutions ensure that your business remains secure and resilient, allowing you to focus on growth and success. Safeguard your enterprise today with reliable coverage designed to meet your unique needs.</p>
          <div className="insurance-details__points-and-text-box">
            <div className="insurance-details__points-box">
              <h3 className="insurance-details__points-title">Our Goals</h3>
              <ul className="insurance-details__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Financial Protection</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Liability Coverage</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Employee Protection</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Business Continuity</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check-mark-1" />
                  </div>
                  <p>Risk Management</p>
                </li>
              </ul>
            </div>
            <div className="insurance-details__text-box">
              <h3>The Challenges</h3>
              <p>Balancing the need for comprehensive coverage <br /> with the cost of insurance premiums can be <br /> challenging, particularly for small businesses <br /> with limited budgets and Implementing effective <br /> safety protocols and risk management strategies.</p>
            </div>
          </div>
          <div className="insurance-details__faq">
            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What is business insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Business insurance is a type of coverage designed to protect businesses from financial losses resulting from unforeseen events, such as property damage, liability claims, or business interruptions.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion  active">
                <div className="accrodion-title">
                  <h4>Why do I need business insurance?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Business insurance is essential for protecting your company's assets, liabilities, and operations from various risks and potential financial hardships. It helps safeguard your business's financial stability and ensures continuity in the event of unexpected events.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>How do I determine the right insurance coverage for my business?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Assess your business's specific risks, industry regulations, and legal requirements. Consult with an insurance agent or broker who specializes in commercial insurance to help identify your coverage needs and tailor a policy that adequately protects your business.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
              <div className="accrodion">
                <div className="accrodion-title">
                  <h4>What factors affect the cost of business insurance premiums?</h4>
                </div>
                <div className="accrodion-content">
                  <div className="inner">
                    <p>Several factors influence insurance premiums, including the type of business, industry risk factors, location, size of the business, revenue, number of employees, claims history, coverage limits, and deductible amounts.</p>
                  </div>{/* /.inner */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InsuranceCategories Business="active"/>
    </div>
  </div>
</section>
{/*Insurance Details End*/}
    </div>
  )
}
export default BussinessInsuranceComponent